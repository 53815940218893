import request from '../utils/request'

//获取头部列表
export function headerlist(data){
    return request({
        url:'/common/field',
        method:'get',
        params:data
    })
}

//获取下拉列表
export function selectlist(data){
    return request({
        url:'/payback/info',
        method:'get',
        params:data
    })
}

// //获取获取列表数据
// export function tablelist(data){
//     return request({
//         url:'/payback/table',
//         method:'get',
//         params:data
//     })
// }

//获取获取列表数据（渠道信息）
// export function tablelist(data){
//     return request({
//         url:'/v1/sell/payBackList',
//         method:'get',
//         params:data
//     })
// }
export function tablelist(data){
    return request({
        url:'/v1/pay_back/list',
        method:'get',
        params:data
    })
}

//添加回款记录
// export function addback(data){
//     return request({
//         url:'/PayBack/save',
//         method:'post',
//         data
//     })
// }

export function addback(data){
    return request({
        url:'/v1/pay_back/add',
        method:'post',
        data
    })
}

//删除汇款记录
// export function delback(data){
//     return request({
//         url:'/payback/del',
//         method:'delete',
//         data
//     })
// }
export function delback(data){
    return request({
        url:'/v1/pay_back/del',
        method:'delete',
        data
    })
}


//获取添加下拉数据
export function getaddselect(data){
    return request({
        url:'/payback/save',
        method:'get',
        params:data
    })
}

//获取回款详情
// export function backdetail(data){
//     return request({
//         url:'/payback/detail',
//         method:'get',
//         params:data
//     })
// }

//获取回款详情(渠道信息)
// export function backdetail(data){
//     return request({
//         url:'/v1/sell/payBackDetail',
//         method:'get',
//         params:data
//     })
// }
export function backdetail(data){
    return request({
        url:'/v1/pay_back/detail',
        method:'get',
        params:data
    })
}

//获取跟进人列表
export function getfollow(data){
    return request({
        url:'/payback/addFollower',
        method:'get',
        params:data
    })
}

//添加跟进人
export function addfollow(data){
    return request({
        url:'/payback/addFollower',
        method:'post',
        data
    })
}

//删除跟进人列表
export function delfollow(data){
    return request({
        url:'/payback/delFollower',
        method:'delete',
        data
    })
}

//编辑回款记录
// export function editback(data){
//     return request({
//         url:'/payback/edit',
//         method:'post',
//         data
//     })
// }
export function editback(data){
    return request({
        url:'/v1/pay_back/edit',
        method:'post',
        data
    })
}


// 上级审核
export function examine(data){
    return request({
        url:'/payback/audit',
        method:'post',
        data
    })
}

//财务审核
export function financeexamine(data){
    return request({
        url:'/payback/financeAudit',
        method:'post',
        data
    })
}

//查看单项明细详情
export function businessdetail(data){
    return request({
        url:'/contract/singleDetail',
        method:'get',
        params:data
    })
}