<template>
    <div class="home">

        <div class="top-search">
            <el-form :model="form" class="demo-form-inline">
                <!-- <div class="select-line bostrap" :style="{height:(hide==false ?'43px':'auto')}" ref="searchLine"> -->
                <div class="select-line bostrap" :style="{height:'auto'}" ref="searchLine">
                    <el-form-item label="用户名" ref="search1">
                        <el-input class="input-height" v-model="form.search " placeholder="请输入关键字搜索"></el-input>
                    </el-form-item>
                    <el-form-item label="时间" ref="search2">
                        <el-date-picker v-model="form.date" type="daterange" range-separator="至" start-placeholder="开始日期"
                            end-placeholder="结束日期" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="状态" ref="search3">
                        <el-select v-model="form.freeze" placeholder="请选择" clearable>
                            <el-option v-for="item in statusList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="职位状态" ref="search3">
                        <el-select v-model="form.resign" placeholder="请选择" clearable>
                            <el-option v-for="item in resignList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="角色类型" ref="search4">
                        <el-select v-model="form.r_id" placeholder="请选择" clearable>
                            <el-option v-for="item in roleList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属部门" ref="search5">
                        <el-cascader class="cascader" placeholder="请选择" :options="typeList" :show-all-levels="false" v-model="form.b_id"
                            :props="{value: 'id', checkStrictly: true, label: 'label' }" clearable>
                        </el-cascader>
                    </el-form-item>
                </div>
                <div class="line"></div>
                <div class="btn-line">
                    <div class="btnpart">
                        <el-button class="confirm" @click="getList('search')" plain v-permission="'成员管理-select'">查询</el-button>
                        <el-button class="cancel" @click="clearSeach" plain v-permission="'成员管理-reset'">重置</el-button>
                    </div>
                    <!-- <div v-if="ishide" class="showpart hover" @click="showHide">
                        {{ actText }}
                        <local-svg iconClass="showdown" class="icon" ></local-svg>
                    </div> -->
                </div>
            </el-form>
        </div>
        <div class="tablepart">
            <memberTable :list="tableData" @transfer="wantAdd" @tableedit="wantEdit" @stopuser="setUserType"
                @deluser="deleteUser"></memberTable>
            <div class="pagetab">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <addUser v-if="dialogAdd" :typelist="typeList" :rolelist="roleList" :show="dialogAdd" @adduser="getAddForm"
            @close="cancelAdd"></addUser>
        <editUser v-if="dialogEdit" :typelist="typeList" :rolelist="roleList" :show="dialogEdit" :editform="editform"
            @edituser="getEditForm" @close="cancelEdit"></editUser>
    </div>
</template>

<script>
    import {getuser,adduser,stopuser,deleteuser,partlist,getrole,edituser } from "@/api/terrace"
    import add from '@/utils/mutual.js'
    import memberTable from "@/components/terrace/member/member.vue"
    import addUser from '@/components/terrace/member/add.vue'
    import editUser from '@/components/terrace/member/edit.vue'
    import elementResizeDetectorMaker from 'element-resize-detector'
    export default{
        name:'member-terrace',
        data(){
            return{
                currentPage:1,
                pageSize:10,
                total:0,
                showAll:false,
                dialogEdit:false,
                dialogAdd:false,
                hide:false,
                typeList:[
                ],
                roleList:[
                ],
                statusList:[
                    {name:'开启',id:1},
                    {name:'禁用',id:0},
                ],
                resignList:[
                    {name:'在职',id:1},
                    {name:'离职',id:0},
                ],
                addform:{},
                editform:{},
                actText:'展开',
                form:{
                    date:[],
                    r_id:'',
                    b_id:[],
                    search:'',
                    freeze:1,
                    resign:1,
                },
                tableData:[],
                ishide:true
            }
        },
        components:{
            memberTable,
            addUser,
            editUser
        },
        mounted(){
            this.searchLine()
            this.getList()
            this.getRoleList()
            this.getPartList()
            const erd = elementResizeDetectorMaker()
            const boxRef = this.$refs.searchLine
            erd.listenTo(boxRef, (element) => {
                console.log(element.clientWidth)
            })
        },
        methods:{
            getRoleList(){
                getrole().then(res=>{
                    this.roleList = res.data
                })
            },
            getPartList(){
                partlist().then(res=>{
                    this.typeList = res.data
                    console.log(this.typeList)
                })
            },
            deleteUser(msg){
                let data = {
                    id:msg
                }
                deleteuser(data).then(res =>{
                    if(res.code==200){
                        this.$message({
                            message:'删除成功',
                            type:'success'
                        })
                        this.getList()
                    }else{
                        this.$message({
                            message:res.msg,
                            type:'error'
                        })
                    } 
                })
            },
            clearSeach(){
                this.form = {
                    date:[],
                    r_id:'',
                    b_id:[],
                    search:'',
                    freeze:1,
                    resign:1,
                }
                this.getList('search')
            },
            setUserType(msg){
                stopuser(msg).then(res =>{
                    if(res.code==200){
                        this.$message({
                            message:'修改成功',
                            type:'success'
                        })
                        this.getList()
                    }else{
                        this.$message({
                            message:res.msg,
                            type:'error'
                        })
                    } 
                })
            },
            // 新建用户
            addNewUser(){
                let data = {
                    b_id:this.addform.b_id,
                    email:this.addform.email,
                    mobile:this.addform.mobile,
                    name:this.addform.name,
                    part:this.addform.partArray,
                    password:this.addform.password,
                    realpass:this.addform.realpass,
                    r_id:this.addform.r_id,
                }
                adduser(data).then(res=>{
                    if(res.code==200){
                        this.getList()
                        this.dialogAdd = false
                        this.$message({
                            message:res.msg,
                            type:'success'
                        })
                    }else{
                        this.$message({
                            message:res.msg,
                            type:'error'
                        })
                    }
                    
                })
            },
            // 获取用户列表
            getList(e){
                if(e){
                    this.currentPage = 1
                }
                let b_id = ''
                if(this.form.b_id.length>0){
                    b_id = this.form.b_id[this.form.b_id.length-1]
                }
                let data = {
                    page:this.currentPage,
                    count:this.pageSize,
                    start:this.form.date.length>1?this.form.date[0]:'',
                    end:this.form.date.length>1?this.form.date[1]:'',
                    freeze:this.form.freeze, //开启1 关闭0
                    search:this.form.search,
                    resign:this.form.resign,
                    r_id:this.form.r_id,
                    b_id:b_id,
                    c_id:1
                }
                console.log(data)
                getuser(data).then(res=>{
                    this.tableData = res.data.table
                    let item = this.tableData
                    for(var a=0;a<item.length;a++){
                        if(item[a].freeze=='是'){
                            this.$set(item[a],'is_open',false)
                        }else{
                            this.$set(item[a],'is_open',true)
                        }
                    }
                    let partarray = ''
                    let rolearray = ''
                    // for(var a=0;a<item.length;a++){
                    //     if(item[a].branch){
                    //         for(var b=0;b<item[a].branch.length;b++){
                    //             if(b==item[a].branch.length-1){
                    //                 partarray = partarray + item[a].branch[b]
                    //             }else{
                    //                 partarray = partarray + item[a].branch[b]+'/'
                    //             }
                    //         }
                    //     }
                    //     if(item[a].role){
                    //         for(var b=0;b<item[a].role.length;b++){
                    //             if(b==item[a].role.length-1){
                    //                 rolearray = rolearray + item[a].role[b]
                    //             }else{
                    //                 rolearray = rolearray + item[a].role[b]+'/'
                    //             }
                    //         }
                    //     }
                    //     item[a].partarray = partarray
                    //     item[a].rolearray = rolearray
                    //     partarray = ''
                    //     rolearray = ''
                    // }
                    this.total = res.data.total
                })
            },
            searchLine(){
                this.$nextTick(()=>{ 
                    let num = document.getElementById('searchLine').offsetHeight
                    console.log(num)
                })
            },
            wantEdit(data){
                this.editform = data
                console.log(data)
                this.dialogEdit = true
            },
            wantAdd(data){
                this.dialogAdd = data
            },
            getAddForm(data){
                this.addform = data
                this.addform.b_id = this.addform.b_id.toString()
                this.addform.r_id = this.addform.role.toString()
                this.addNewUser()
            },
            getEditForm(msg){
                this.editform = msg
                this.editform.r_id = this.editform.role.toString()
                this.editform.b_id = this.editform.b_id.toString()
                let data = {
                    b_id:this.editform.b_id,
                    id:this.editform.id,
                    email:this.editform.email,
                    mobile:this.editform.mobile,
                    name:this.editform.name,
                    part:this.editform.partArray,
                    r_id:this.editform.r_id,
                }
                if(this.editform.password){
                    data.password = this.editform.password
                }
                edituser(data).then(res=>{
                    if(res.code==200){
                        // consoloe.log(res)
                        this.getList()
                        this.dialogEdit = false
                        this.$message({
                            message:res.msg,
                            type:'success'
                        })
                    }else{
                        this.$message({
                            message:res.msg,
                            type:'error'
                        })
                    }
                })
            },
            showHide(){
                this.actText = this.hide?'展开':'收起'
                this.hide = !this.hide
            },
            cancelAdd(){
                this.dialogAdd = false
            },
            cancelEdit(){
                this.dialogEdit = false
            },
            handleSizeChange(val){
                this.pageSize = val
                this.currentPage = 1
                this.getList()
            },
            handleCurrentChange(val){
                this.currentPage = val
                this.getList()
        },
    }
}

</script>

<style src="@/assets/scss/formstyle.scss" lang="scss" ></style>
<style lang="scss" scoped>
* {
    box-sizing: content-box;
}

.home {
    padding: 24px;

    .top-search {
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        .select-line {
            display: flex;
            padding: 0 20px 0 0;
        }

        .line {
            width: 100%;
            height: 1px;
            background: #F0F1F5;
        }

        .btn-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 11px 20px;

            .act {
                background: #2C57FF;
                color: white;
            }

            .btnpart button {
                height: 32px;
                width: 68px;
                line-height: 32px;
                padding: 0;

            }
            .showpart{
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 14px;
                .icon{
                    width: 8px;
                    height: 8px;
                    margin-left: 6px;
                }
            }
            
        }


    }

    .tablepart {
        // min-height: 658px;
        background: white;
        margin-top: 10px;
        border-radius: 4px;
        padding: 16px 20px;

        .pagetab {
            text-align: right;
            margin-top: 20px;
        }
    }
}

.btnpart {
    button {
        height: 32px;
        width: 68px;
        line-height: 32px;
        padding: 0;

    }

    .act {
        background: #2C57FF;
        color: white;
    }
}
</style>