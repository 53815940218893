import request from '../utils/request'

//获取头部列表
export function headerlist(data){
    return request({
        url:'/common/field',
        method:'get',
        params:data
    })
}

//获取开票添加列表
export function addselect(data){
    return request({
        url:'/invoice/option',
        method:'get',
        params:data
    })
}

//获取开票列表
// export function openlist(data){
//     return request({
//         url:'/invoice/index',
//         method:'get',
//         params:data
//     })
// }

//根据回款获取渠道信息
export function bankchannel(data){
    return request({
        url:'/v1/sell/payBackChannel',
        method:'get',
        params:data
    })
}

// 开票管理带渠道
// export function openlist(data){
//     return request({
//         url:'/v1/sell/invoiceList',
//         method:'get',
//         params:data
//     })
// }
export function openlist(data){
    return request({
        url:'/v1/invoice/invoiceList',
        method:'get',
        params:data
    })
}


// //添加开票
// export function addopne(data){
//     return request({
//         url:'/invoice/create',
//         method:'post',
//         data
//     })
// }

// 添加开票
export function addopne(data){
    return request({
        url:'v1/invoice/saveinvoice',
        method:'post',
        data
    })
}
//开票详情
// export function opendetail(data){
//     return request({
//         url:'/invoice/detail',
//         method:'get',
//         params:data
//     })
// }
export function opendetail(data){
    return request({
        url:'/v1/invoice/detail',
        method:'get',
        params:data
    })
}

//删除开票
// export function delopen(data){
//     return request({
//         url:'/invoice/delete',
//         method:'delete',
//         data
//     })
// }
export function delopen(data){
    return request({
        url:'/v1/invoice/delInvoice',
        method:'delete',
        data
    })
}

//批量删除开票
export function delmoreopen(data){
    return request({
        url:'/invoice/del',
        method:'delete',
        data
    })
}

//编辑开票
// export function editopen(data){
//     return request({
//         url:'/invoice/edit',
//         method:'post',
//         data
//     })
// }
export function editopen(data){
    return request({
        url:'/v1/invoice/editInvoice',
        method:'post',
        data
    })
}

//添加跟进人
export function addfollow(data){
    return request({
        url:'/invoice/addFollow',
        method:'post',
        data
    })
}

//删除跟进人
export function delfollow(data){
    return request({
        url:'/invoice/delFollower',
        method:'delete',
        data
    })
}

//开票审核
export function billexamine(data){
    return request({
        url:'/invoice/audit',
        method:'post',
        data
    })
}

//添加下级列表获取
// export function adddownselect(data){
//     return request({
//         url:'/invoice/clientNumber',
//         method:'get',
//         params:data
//     })
// }
export function adddownselect(data){
    return request({
        url:'/v1/invoice/clientNumber',
        method:'get',
        params:data
    })
}


//回款总金额
export function totalmoney(data){
    return request({
        url:'/invoice/clientAmount',
        method:'get',
        params:data
    })
}

//获取审批人
export function checkuser(data){
    return request({
        url:'/invoice/companyDrawer',
        method:'get',
        params:data
    })
}

// 纳税人列表
export function buyerlist(data){
    return request({
        url:'/taxpayer/index',
        method:'get',
        params:data
    })
}

// 添加购买方
export function addbuyer(data){
    return request({
        url:'/taxpayer/create',
        method:'post',
        data
    })
}

// 编辑购买方
export function editbuyer(data){
    return request({
        url:'/taxpayer/edit',
        method:'post',
        data
    })
}

//删除购买方
export function delbuyer(data){
    return request({
        url:'/taxpayer/delete',
        method:'delete',
        data
    })
}

//完善回款单号
export function improvebill(data){
    return request({
        url:'/invoice/perfect',
        method:'post',
        data
    })
}