<template>
    <div class="home">
        <div class="border"  v-if="!isDetail">
            <div class="top-search">
                <el-form :model="form" class="demo-form-inline" label-width="">
                    <div class="select-line bostrap " :style="{ height: (hide == false ? '56px' : 'auto') }" id="searchLine">
                        <el-form-item label=" " class="lb">
                            <el-input class="input-height select-input" v-model="form.DB" placeholder="请输入关键字搜索">
                                <el-select slot="prefix" placeholder="请选择" v-model="HD">
                                    <el-option label="房屋回款单号" value="1"></el-option>
                                    <el-option label="备注" value="2"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="所属部门" ref="search5" >
                            <el-cascader class="cascader" popper-class="ssdepart cascader-branch-select" placeholder="请选择" :options="branchArr"
                                :show-all-levels="false" v-model="form.department" :props="{ value: 'id', label: 'label',checkStrictly: true, }"
                                clearable filterable>
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="创建人">
                            <el-select v-model="form.createmen" placeholder="请选择" filterable>
                                <el-option v-for="item in alladArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="客户名称" >
                            <el-select v-model="form.custom" placeholder="请选择" filterable popper-class="custom-select">
                                <el-option v-for="item in kehuArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
                            </el-select>

                        </el-form-item>
                        <el-form-item label="业务明细">
                            <el-select v-model="form.busdet" placeholder="请选择" filterable>
                                <el-option v-for="item in ywmxArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="回款金额">
                            <div class="rangeinput">
                                <el-input clearable @focus="bordercolor" @blur="borderlost" v-model="form.collstart"
                                    placeholder="请输入金额"></el-input>
                                至
                                <el-input clearable @focus="bordercolor" @blur="borderlost" v-model="form.collend"
                                    placeholder="请输入金额"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="到款账户">
                            <el-select v-model="form.collstar" placeholder="请选择" filterable>
                                <el-option v-for="item in dkzhArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="实际到账时间">
                            <el-date-picker v-model="form.realitytime" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item class="zuback" label="租赁回款状态">

                            <el-cascader popper-class="ssdepart" class="client_id" placeholder="请选择" :options="zlhkztArr"
                                collapse-tags v-model="form.leasepay"
                                :props="{ multiple: true, value: 'id', checkStrictly: true, label: 'name' }" filterable
                                clearable>
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="开始日期">
                            <el-date-picker v-model="form.starttime" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="结束日期">
                            <el-date-picker v-model="form.endtime" type="daterange" range-separator="至" start-placeholder="开始日期"
                                end-placeholder="结束日期" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="回款方式">

                            <el-cascader popper-class="ssdepart" class="client_id" placeholder="请选择" :options="hkfsArr"
                                collapse-tags v-model="form.payway"
                                :props="{ multiple: true, value: 'id', checkStrictly: true, label: 'name' }" filterable
                                clearable>
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="租赁合同">
                            <el-select v-model="form.leasecontract" placeholder="请选择" filterable>
                                <el-option v-for="item in zuArr" :label="item.num" :value="item.id" :key="item.id"></el-option>
                            </el-select>
                            <!-- <el-cascader popper-class="ssdepart" class="client_id" placeholder="请选择" :options="zuArr"
                                collapse-tags v-model="form.leasecontract"
                                :props="{ multiple: true, value: 'id', checkStrictly: true, label: 'num' }" filterable
                                clearable>
                            </el-cascader> -->
                        </el-form-item>
                        <el-form-item label="创建时间">
                            <el-date-picker v-model="form.createtime" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                    </div>
                    <div class="line"></div>
                    <div class="btn-line">
                        <div class="btnpart">

                            <el-button class="confirm" @click="getList('search')" plain v-permission="'房屋回款-select'">查询</el-button>
                            <el-button class="cancel" @click="clearSeach" plain v-permission="'房屋回款-reset'">重置</el-button>
                        </div>
                        <div style="font-size: 14px;" class="showpart hover fi" @click="showHide">
                            {{ actText }}
                            <!-- <img v-if="!hide" src="../../assets/image/showselect.png" alt="">
                            <img v-if="hide" src="../../assets/image/showselect.png" alt="" style="transform: rotate(-180deg);"> -->
                            <local-svg iconClass="showdown"  class="icon" :class="hide?'acticon':''"></local-svg>

                        </div>
                    </div>
                </el-form>
            </div>
            <!-- 表单 -->
            <div class="tablepart">
                <div class="top">
                    <span>房屋回款</span>
                    <div class="btnlist btnpart">
                        <el-button class="iconbtn" style="height:32px !important ;padding: 0 14px;" @click="batchDE" v-permission="'房屋回款-batch_del'"
                            plain><local-svg iconClass="delete" class="icon"></local-svg>批量删除</el-button>
                        <el-button class="iconbtn" style="height:32px !important ;padding: 0 14px;" @click="showDialog = true"  v-permission="'房屋回款-list'"
                            plain><local-svg iconClass="set" class="icon"></local-svg>设置列表展示列</el-button>
                        <el-button class="iconbtn" style="height:32px !important ;padding: 0 14px;" @click="exportD" v-permission="'房屋回款-export'"
                            plain><local-svg iconClass="expor" class="icon"></local-svg>导出</el-button>
                        <el-button class="iconbtn" @click="newAdd" style="height:32px !important ;padding: 0 14px;" v-permission="'房屋回款-create'"
                            plain><local-svg iconClass="add" class="icon"></local-svg>新建</el-button>
                    </div>
                </div>

                <customTable v-if="headerList.length > 0" ref="dadtable" :list="tableData" :head="showHeader" @detail="Dandetail"
                    @tableedit="wantEdit" @deluser="deluser" @PL="pldel" :loading="load" :mergeIds="mergeIds">
                </customTable>
                <div class="pagetab">
                    <span style="font-size: 14px;color: #B0B1B9;">回款金额合计 <span style="margin: 0 8px;color: #2C57FF;font-size: 16px;
                        font-weight: bold;">{{ huimoney }}</span>
                        元</span>
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <newhousevack v-if="addDialog" :show="addDialog" @cancel="newcancel" @add="addback" :ywmxList="ywmxArr" :hkfsList="hkfsArr" :generateData="generateData"
            :branchList="branchList" :khList="kehuArr" :ht="zuArr" >
        </newhousevack>
        <edithouseback v-if="editDia" :show="editDia" :edlist="edlist" @edit="edend" @cancel="newcancel" :ywmxList="ywmxArr"
            :hkfsList="hkfsArr" :branchList="branchList" :khList="kehuArr" :ht="zuArr" :arriveList="arrivelist"></edithouseback>
        <backdetail v-if="isDetail" @back="closeDetail" :danList="danList" :isDetail="isExamine" @print="printIt" :house_num="house_num"></backdetail>
        <!-- <newhouse :show="addDialog" @add="Addhouse" @cancel="addcancel" :xialaList="xialalist" :huiway="huiway"
            :xToken="xToken"></newhouse>
        <Dandetail v-if="isDetail" @back="closeDetail" :danList="danList"></Dandetail>
        
        <edithouse :show="editDia" :edlist="edlist" @cancel="addcancel" :xialaList="xialalist" :edhuiway="edhuiway"
            :xToken="edxToken" @edit="ed">
        </edithouse>-->
        <setshowDialog v-if="showDialog" :list="headerList" :show="showDialog" @show="setShow" @cancel="closeShow">
        </setshowDialog>
        <exportDialog v-if="exportDialog" :list="expList" :show="exportDialog" @export="exportTable" @cancel="cancelExport">
        </exportDialog>
        <div  class="printpart" v-if="isPrint">
            <!--   -->
            <section id="printArea" style="margin-top: 60px;" v-if="printElem">
                <p class="printArea-title">
                    <img height="50" src="@/assets/image/houseback.png" alt="">
                    <img src="@/assets/image/printLogo.png" class="printArea-title-img" />
                </p>
                <div style="
                    display: flex;
                    justify-content: space-around;
                    padding-bottom: 5px;
                    margin-top: 20px;
                    color: #000;
                    ">
                    <!-- <p>回款日期： {{ inputs.back_time }}</p> -->
                    <p>实际到账时间： {{ inputs.real_time }}</p>
                    <p>回款审核状态： {{ inputs.audit_status == 15 ?'未提审':inputs.audit_status == 16 ?'审核中':inputs.audit_status == 17 ?'审核通过':inputs.audit_status == 18 ?'审核拒绝':'财务已确认'}}</p>
                </div>
                <table class="printTable">
                    <tbody>
                    <tr>
                        <td>房屋回款单号</td>
                        <td>{{ inputs.house_back_num }}</td>
                        <!-- id > 10000 ? "HT#000"+id : id > 100000 ? "HT#00"+id : "HT#0000"+id -->
                        <td>所属部门</td>
                        <td>{{ inputs.branch_name }}</td>
                        <td>租赁合同</td>
                        <td>{{ inputs.house_num }}</td>
                    </tr>
                    <tr>
                        <td>客户</td>
                        <td colspan="2">{{ inputs.client }}</td>
                        <td>回款总额</td>
                        <td colspan="2">{{ inputs.total_amount }}元</td>
                    </tr>
                    <tr>
                        <td>房屋地址</td>
                        <td colspan="5">{{ inputs.location }}</td>
                    </tr>
                    <template v-for="(item,index) in inputs.business_arr" >
                        <tr :key="index">
                            <td>开始日期{{ inputs.business_arr.length > 1 ? index + 1 : '' }}</td>
                            <td colspan="2">{{ item.start_time }}</td>
                            <td>结束日期{{ inputs.business_arr.length > 1 ? index + 1 : '' }}</td>
                            <td colspan="2">{{ item.end_time }}</td>
                        </tr>
                        <tr :key="index+'child'">
                            <td>业务明细{{ inputs.business_arr.length > 1 ? index + 1 : '' }}</td>
                            <td colspan="2">{{ item.business_detail.name }}</td>
                            <td>回款金额{{ inputs.business_arr.length > 1 ? index + 1 : '' }}</td>
                            <td colspan="2">{{ item.pay_amount }}元</td>
                        </tr>
                    </template>
                    <tr>
                        <td>回款方式</td>
                        <td colspan="2">{{ inputs.pay_type }}</td>
                        <td>到款账户</td>
                        <td colspan="2">{{ inputs.pay_account }}</td>
                        <!-- <td>回款金额</td>
                        <td>{{ inputs.total_amount }}元</td> -->
                    </tr>

                    <tr>
                        <td>备注</td>
                        <td colspan="5">{{ inputs.remark }}</td>
                    </tr>
                    </tbody>
                </table>
                <div style="display: flex; justify-content: space-around; padding-top: 10px;color: #000;">
                    <!-- <p>财务： {{ inputs.finance }}</p> -->
                    <p style="color: #000 !important;">财务： 耿玉兰</p>
                    <p style="color: #000 !important;">制单人： {{ inputs.create_name }}</p>

                </div>
            </section>
        </div>
    </div>
</template>
<script>
import setshowDialog from "@/components/custom/show.vue"
import exportDialog from "@/components/houseContract/export.vue"
import {saveheader} from '@/api/common'
import customTable from "@/components/custom/table.vue"
import backdetail from '@/views/houseCollection/components/backdetail.vue'
import newhousevack from '@/views/houseCollection/components/newhouseback.vue'
import edithouseback from '@/views/houseCollection/components/edithouseback.vue'
import printJS from "print-js";
import { housebackList, headlist, backde, branchxl, alladmin, kehu, ydzh, zlht, backdel, exportContract, exportdata } from './api'
export default {
    name: 'house-collection',
    data() {
        return {
            danList: {},
            expList: {},
            editDia: false,
            edlist: {},
            showDialog: false,
            isDetail: false,
            addDialog: false,
            exportDialog: false,
            isExamine:false,
            hide: false,
            actText: '展开',
            HD: '1',
            house_num:'',
            currentPage: 1,
            pageSize: 10,
            total: 0,
            tableData: [],
            headerList: [],
            showHeader: [],
            huimoney: '',

            form: {
                DB: '',//单号备注合并
                department: '',//所属部门
                createmen: '',//创建人
                custom: '',//客户名称
                busdet: '',//业务明细
                collstart: '',//汇款金额
                collend: '',
                collstar: '',//到款账户
                realitytime: '',//实际到账时间
                leasepay: '',//租赁回款状态
                starttime: '',//开始日期
                endtime: '',//结束日期
                payway: '',//回款方式
                leasecontract: '',//租赁合同
                createtime: ''//创建时间

            },
            branchArr: [],
            alladArr: [],
            kehuArr: [],
            zuArr: [],
            ywmxArr: [],
            dkzhArr: [],
            zlhkztArr: [],
            hkfsArr: [],
            plnum: [],
            arrivelist:[],
            load:true,
            branchList:[],
            mergeIds:[],
            printElem:false,
            inputs:{},
            isPrint:true,
            generateData:{}
            // branArr: []
        }

    },
    components: {
        customTable,
        newhousevack,
        backdetail,
        setshowDialog,
        exportDialog,
        edithouseback


    },
    mounted() {
        if(this.$route.query.id){
            if(this.$route.query.type && this.$route.query.type=='examine'){
                let data = {
                    id:this.$route.query.id
                }
                this.Dandetail(data)
                this.isExamine = true
            }
            if(this.$route.query.type && this.$route.query.type=='detail'){
                let data = {
                    id:this.$route.query.id
                }
                this.Dandetail(data)
            }
            if(this.$route.query.type && this.$route.query.type=='generate'){
                this.generateData = this.$route.query
            }
        }
        if(this.$route.params.id){
            if(this.$route.params.type && this.$route.params.type=='examine'){
                let data = {
                    id:this.$route.params.id
                }
                this.Dandetail(data)
                this.isExamine = true
            }
            if(this.$route.params.type && this.$route.params.type=='detail'){
                let data = {
                    id:this.$route.params.id
                }
                this.Dandetail(data)
            }
            if(this.$route.params.type && this.$route.params.type=='generate'){
                this.generateData = this.$route.params
            }
        }
        this.getHeaderList()
        this.getList()
        this.Allxiala()
        this.newAdd = this.$debounce(this.newAdd )
        this.exportD = this.$debounce(this.exportD )
    },
    methods: {
        printIt(msg){
            this.printElem = true;
            this.inputs = msg
            console.log(this.inputs,111)
            let start_arr = []
            let end_arr = []
            let amount_arr = []
            let business_arr = []
            let total_amount = 0
            this.inputs.business_arr.forEach(item =>{
                start_arr.push(item.start_time)
                end_arr.push(item.end_time)
                amount_arr.push(item.pay_amount)
                business_arr.push(item.business_detail.name)
                total_amount = total_amount + Number(item.pay_amount )
            })
            console.log( end_arr,end_arr.sort(this.sortDatesDescending))
            this.inputs.date1 = start_arr.sort(this.sortDatesDescending)[start_arr.length - 1]
            this.inputs.date2 = end_arr.sort(this.sortDatesDescending)[0]
            this.inputs.total_amount = total_amount.toFixed(2)
            this.inputs.bus_name = business_arr.join('、')
            var that = this;
            setTimeout(() => {
                printJS({
                    style: ['@page { margin: 0cm 1.7cm 0cm 1.8cm;  margin-top: 1mm; margin-bottom: 1mm;}  body{margin-top: 60px;}'],
                    // style: [' @media print{@page {size:landscape}} @page { margin: 10px; } html { padding: 0 40px 0 40px; }  body{margin-top: 40px;}'], // @page { margin: 0px; } body {margin-top: 50px;}
                    printable: "printArea",
                    type: "html",
                    targetStyles: ['*'],
                    onLoadingEnd: function () {
                        that.printElem = false;
                    },
                    ignoreElements: ["ignore"],
                });
            }, 200);
        },
        sortDatesDescending(a, b) {  
            // 将字符串转换为Date对象  
            let dateA = new Date(a);  
            let dateB = new Date(b);  
        
            // 返回时间差，实现降序排序  
            // 如果dateA比dateB晚，则返回正数；如果更早，则返回负数  
            return dateB - dateA;  
        },
        edend(val) {
            this.editDia = val,
                this.getList()
        },
        // 设置展示列保存&取消
        setShow(msg) {
            let ids = []
            msg.forEach(item =>{
                ids.unshift(item.id)
            })
            let data = {
                id:ids.toString(),
                type:7
            }
            saveheader(data).then(res =>{
                if(res.code == 200){
                    this.$message.success(res.msg)
                    this.showHeader = msg
                    this.showDialog = false
                    this.getHeaderList()
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        closeShow(msg) {
            this.showDialog = false
        },
        closeDetail() {
            this.isDetail = false
            this.getList()
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.currentPage = 1
            this.getList()
        },
        handleCurrentChange(val) {
            // console.log(val);
            this.currentPage = val
            this.getList()
        },
        showHide() {
            this.actText = this.hide ? '展开' : '收起'
            this.hide = !this.hide
        },
        async getList(e) {
            if(e){
                this.currentPage = 1
            }
            this.load = true
            let huikuantypeArr = []
            if (this.form.leasepay.length > 0) {
                huikuantypeArr = this.form.leasepay.map((item) => {
                    return item[0]
                })
            }
            let huikuanfangshi = []
            if (this.form.payway.length > 0) {
                huikuanfangshi = this.form.payway.map((item) => {
                    return item[0]
                })
            }
            let data = {
                page: this.currentPage,
                count: this.pageSize,
                search: this.HD === '1' ? this.form.DB : '',
                remark: this.HD === '2' ? this.form.DB : '',
                belong_branch: [this.form.department[this.form.department.length - 1]],
                c_id: this.form.custom,
                pay_amount: [this.form.collstart, this.form.collend],
                audit_status: huikuantypeArr,
                create_id: this.form.createmen,
                start_time: [this.form.starttime[0], this.form.starttime[1]],
                end_time: [this.form.endtime[0], this.form.endtime[1]],
                pay_type: huikuanfangshi,
                rent_contract: this.form.leasecontract,
                real_time: [this.form.realitytime[0], this.form.realitytime[1]],
                time_range: [this.form.createtime[0], this.form.createtime[1]],
                business_detail: this.form.busdet,
                pay_account: this.form.collstar
            }
            let res = await housebackList(data)
            // console.log(res);
            this.huimoney = res.data.total_amount
            // this.tableData = res.data.table
            res.data.table.forEach(item =>{
                let total_money = 0
                if(item.business_arr.length > 0){
                    item.business_arr.forEach(val =>{
                        total_money = total_money + Number(val.pay_amount)
                    })
                }
                item.total_amount = total_money.toFixed(2)
            })
            this.tableData = this.transformData(res.data.table)
            this.tableData.forEach(item =>{
                item.bill_num = item.releate_invoice && item.releate_invoice.number? item.releate_invoice.number : ''
                item.bill_id = item.releate_invoice && item.releate_invoice.id? item.releate_invoice.id : ''
            })
            this.total = res.data.total
            setTimeout(() => {
                this.load = false
            }, 1000);
        },
        transformData(data) {
            var result = [];
            for (var i = 0; i < data.length; i++) {
                var item = data[i];
                if (item.business_arr.length > 0) {
                    for (var j = 0; j < item.business_arr.length; j++) {
                        var count = j == 0 ? item.business_arr.length : 0;
                        var newItem = Object.assign({}, item, item.business_arr[j], {cow_id:item.id + '-'+ j,count: count,business_detail:item.business_arr[j].business_detail && item.business_arr[j].business_detail.name ? item.business_arr[j].business_detail.name : '无',big_amount:this.bigMoney(item.business_arr[j].pay_amount) });
                        delete newItem.business_arr;
                        result.push(newItem);
                    }
                } 
                else {
                    var newItem = Object.assign({}, item, { count: 1,business_detail:'',big_amount:'' });
                    result.push(newItem);
                }
            }
            return result;
        },
        // batchDE() { },
        exportD() { },
        newAdd() {
            this.addDialog = true
        },
        newcancel(val) {
            this.addDialog = val
            this.editDia = val
        },
        addback(val) {
            this.addDialog = val
            this.getList()
        },
        bordercolor() {

            document.querySelector('.rangeinput').classList.add('rangeinputborder')
        },
        borderlost() {
            document.querySelector('.rangeinput').classList.remove('rangeinputborder')

        },

        clearSeach() {
            this.form = {
                DB: '',//单号备注合并
                department: '',//所属部门
                createmen: '',//创建人
                custom: '',//客户名称
                busdet: '',//业务明细
                collstart: '',//汇款金额
                collend: '',
                collstar: '',//到款账户
                realitytime: '',//实际到账时间
                leasepay: '',//租赁回款状态
                starttime: '',//开始日期
                endtime: '',//结束日期
                payway: '',//回款方式
                leasecontract: '',//租赁合同
                createtime: ''//创建时间

            },
            this.getList('search')
        },
        // 表头
        getHeaderList() {
            headlist(7).then(res => {
                this.showHeader = []
                this.headerList = res.data
                this.headerList.forEach(item => {
                    this.$set(item, 'check', item.is_show==1?true:false)
                    if(item.is_show==1){
                        this.showHeader.push(item)
                    }
                })
                this.showHeader.forEach((item,index) =>{
                    if(item.name == '业务明细'||item.name == '回款金额(元)'||item.name == '开始日期'||item.name == '结束日期'||item.name == '大写金额'){
                        this.mergeIds.push(index)
                    }
                })
                this.$nextTick(() => {
                    this.$refs.dadtable.$refs.showTable.doLayout()
                })
            })
        },
        async Dandetail(val) {
            console.log(val);
            let res = await backde(val.id)
            this.danList = res.data
            this.house_num  = val.house_contract_id
            this.isDetail = true
        },
        async wantEdit(val) {
            // console.log(val);
            let res = await backde(val.info.id)
            // console.log(res);
            this.edlist = res.data
            this.editDia = true

            // console.log(val);
        },
        // 删除一个
        async deluser(val) {
            // console.log(val);
            let arr = []
            arr.push(val)
            let res = await backdel(arr)
            // console.log(res);
            if (res.code === 200) {
                this.$message({
                    type: 'success',
                    message: '数据删除成功'
                });
                this.getList()
            } else {
                this.$message({
                    type: 'error',
                    message: '当前没有被允许删除数据'
                });
            }

        },
        // 批量删除
        batchDE() {
            if (this.plnum.length>0) {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    let res = await backdel(this.plnum)
                    // console.log(res);
                    if (res.code === 403) {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                    } else {
                        this.$message({
                            type: 'success',
                            message: res.msg
                        });
                    }

                    this.getList()
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                this.$message({
                    type: 'error',
                    message: '当前没有选中删除内容'
                });
            }
        },
        pldel(val) {
            this.plnum = val
            // let res = await delhouse(val)
            // console.log(res);
        },
        // 获取所有下拉筛选
        async Allxiala() {
            let brarr = await branchxl()
            this.branchArr = brarr.data
            let allad = await alladmin()
            this.alladArr = allad.data
            let kehuu = await kehu()
            this.kehuArr = kehuu.data
            // console.log(kehuu);
            let za = await ydzh()

            this.ywmxArr = za.data.option.business_detail
            this.dkzhArr = za.data.option.pay_account
            this.zlhkztArr = za.data.option.audit_status
            this.hkfsArr = za.data.option.pay_type
            // this.branchArr = za.data.option.branch
            this.branchList = za.data.option.branch
            this.arrivelist = za.data.option.pay_account
            // console.log(za);
            let zu = await zlht()

            this.zuArr = zu.data
            if(this.generateData.id){
                this.newAdd()
            }

        },
        async exportTable(msg) {
            let typelist = ''
            for(var a = 0; a<msg.length;a++){
                let item =  msg[a]
                if( a < msg.length-1){
                    typelist = typelist + `${item.type}=${item.name}&`
                    
                }else{
                    typelist = typelist + `${item.type}=${item.name}` 
                }
            }
            let cusArr = []
            if (this.form.custom.length > 0) {
                cusArr = this.form.custom.map((item) => {
                    return item[0]
                })
            }
            let huikuantypeArr = []
            if (this.form.leasepay.length > 0) {
                huikuantypeArr = this.form.leasepay.map((item) => {
                    return item[0]
                })
            }
            // let creatArr = []
            // if (this.form.createmen.length > 0) {
            //     creatArr = this.form.createmen.map((item) => {
            //         return item[0]
            //     })
            // }
            let huikuanfangshi = []
            if (this.form.payway.length > 0) {
                huikuanfangshi = this.form.payway.map((item) => {
                    return item[0]
                })
            }
            // let htArr = []
            // if (this.form.leasecontract.length > 0) {
            //     htArr = this.form.leasecontract.map((item) => {
            //         return item[0]
            //     })
            // }
            // let ywArr = []
            // if (this.form.busdet.length > 0) {
            //     ywArr = this.form.busdet.map((item) => {
            //         return item[0]
            //     })
            // }
            // let dkArr = []
            // if (this.form.collstar.length > 0) {
            //     dkArr = this.form.collstar.map((item) => {
            //         return item[0]
            //     })
            // }
            let data = {
                search: this.HD === '1' ? this.form.DB : '',
                belong_branch:this.form.department[this.form.department.length - 1]?this.form.department[this.form.department.length - 1]:'',
                create_id:this.form.createmen,
                c_id:cusArr.toString(),
                business_detail:this.form.busdet,
                pay_amount:this.form.collstart&&this.form.collend?[this.form.collstart, this.form.collend]:"",
                pay_account:this.form.collstar,
                real_time:this.form.realitytime[0]&&this.form.realitytime[1]?[this.form.realitytime[0], this.form.realitytime[1]].toString():'',
                audit_status:huikuantypeArr.toString(),
                start_time:this.form.starttime[0]&&this.form.starttime[1]?[this.form.starttime[0], this.form.starttime[1]].toString():'',
                end_time:this.form.endtime[0]&&this.form.endtime[1]?[this.form.endtime[0], this.form.endtime[1]].toString():'',
                pay_type:huikuanfangshi.toString(),
                rent_contract:this.form.leasecontract,
                time_range: this.form.createtime[0]&&this.form.createtime[1]?[this.form.createtime[0], this.form.createtime[1]].toString():'',
                remark:this.HD === '2' ? this.form.DB : '',
            }
            console.log(typelist)
            let string = `search|${data.search};belong_branch|${data.belong_branch};create_id|${data.create_id};c_id|${data.c_id};business_detail|${data.business_detail};pay_amount|${data.pay_amount};pay_account|${data.pay_account};real_time|${data.real_time};audit_status|${data.audit_status};start_time|${data.start_time};end_time|${data.end_time};pay_type|${data.pay_type};rent_contract|${data.rent_contract};time_range|${data.time_range};remark|${data.remark}`
            console.log(string)
            window.open(`${this.$BASE_URL}houseBack/exportData?${typelist}&token=${localStorage.getItem('token')}&export=${string}`,'_blank')
            this.exportDialog = false

        },
        cancelExport() {
            this.exportDialog = false
        },
        async exportD() {
            // console.log(this.headerList);
            let res = await exportdata()
            // console.log(res.data);
            this.expList = [
                { name: res.data.num, check: true, type: 'num' },
                { name: res.data.alter_people, check: true, type: 'alter_people' },
                { name: res.data.audit_status, check: true, type: 'audit_status' },
                { name: res.data.branch, check: true, type: 'branch' },
                { name: res.data.business_detail, check: true, type: 'business_detail' },
                { name: res.data.client, check: true, type: 'client' },
                { name: res.data.create_people, check: true, type: 'create_people' },
                { name: res.data.create_time, check: true, type: 'create_time' },
                { name: res.data.end_time, check: true, type: 'end_time' },
                { name: res.data.house_contract, check: true, type: 'house_contract' },
                { name: res.data.pay_account, check: true, type: 'pay_account' },
                { name: res.data.pay_amount, check: true, type: 'pay_amount' },
                { name: res.data.pay_type, check: true, type: 'pay_type' },
                { name: res.data.preferential_matter, check: true, type: 'preferential_matter' },
                { name: res.data.real_time, check: true, type: 'real_time' },
                { name: res.data.refuse, check: true, type: 'refuse' },
                { name: res.data.remark, check: true, type: 'remark' },
                { name: res.data.start_time, check: true, type: 'start_time' },
                { name: res.data.type, check: true, type: 'type' },
                { name: res.data.update_time, check: true, type: 'update_time' },
                { name: res.data.profit, check: true, type: 'profit' },
            ]
            // console.log(res.data);
            this.exportDialog = true
        },
        numToCapital(money) {
            var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');	 //汉字的数字
            var cnIntRadice = new Array('', '拾', '佰', '仟'); //基本单位
            var cnIntUnits = new Array('', '万', '亿', '兆');  //对应整数部分扩展单位
            var cnDecUnits = new Array('角', '分', '毫', '厘'); //对应小数部分单位
            var cnInteger = '整';	//整数金额时后面跟的字符
            var cnIntLast = '元';	//整数完以后的单位
            //最大处理的数字
            var maxNum = 999999999999999.9999;
            var integerNum;	 //金额整数部分
            var decimalNum;	 //金额小数部分
            //输出的中文金额字符串
            var chineseStr = '';
            var parts;		//分离金额后用的数组，预定义
            if (money == '') { return ''; }
        
            money = parseFloat(money);
            if (money >= maxNum) {
                //超出最大处理数字
                return '超出最大处理数字';
            }
            if (money == 0) {
                chineseStr = cnNums[0] + cnIntLast + cnInteger;
                return chineseStr;
            }
            //四舍五入保留两位小数,转换为字符串
            money = Math.round(money * 100).toString();
            integerNum = money.substr(0,money.length-2);
            decimalNum = money.substr(money.length-2);
            if(decimalNum.length == 1){
                decimalNum = '0' + decimalNum
            }
            //获取整型部分转换
            if (parseInt(integerNum, 10) > 0) {
                var zeroCount = 0;
                var IntLen = integerNum.length;
                for (var i = 0; i < IntLen; i++) {
                    var n = integerNum.substr(i, 1);
                    var p = IntLen - i - 1;
                    var q = p / 4;
                    var m = p % 4;
                    if (n == '0') {
                        zeroCount++;
                    } else {
                        if (zeroCount > 0) {
                            chineseStr += cnNums[0];
                        }
                        //归零
                        zeroCount = 0;
                        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                    }
                    if (m == 0 && zeroCount < 4) {
                        chineseStr += cnIntUnits[q];
                    }
                }
                chineseStr += cnIntLast;
            }
            //小数部分
            if (decimalNum != '') {
                var decLen = decimalNum.length;
                for (var i = 0; i < decLen; i++) {
                    var n = decimalNum.substr(i, 1);
                    if (n != '0') {
                        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                    }
                }
            }
            if (chineseStr == '') {
                chineseStr += cnNums[0] + cnIntLast + cnInteger;
            } else if (decimalNum == '' || /^0*$/.test(decimalNum)) {
                chineseStr += cnInteger;
            }
            return chineseStr;
        },
        bigMoney(money) {
            let number = Number(money)
            if(number && number>=0){
                return this.numToCapital(number)
            }else if(number && number<0){
                return '负' + this.numToCapital(Number(number)*-1)
            }
        },

    },
}
</script>
<style src="@/assets/scss/formstyle.scss" lang="scss" ></style>

<style lang="scss" scoped>
.home {
    .border{
        padding: 24px;
    }
    .top-search {
        // min-height: 120px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;

        .select-line {
            display: flex;
            padding: 12px 20px 0 0 !important;

            :deep(.el-form-item) {
                margin-top: 0px !important;
                margin-bottom: 12px !important;
            }
        }

        .line {
            width: 100%;
            height: 1px;
            background: #F0F1F5;
            // margin-top: 12px;
        }

        .btn-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 14px 20px;
            height: 27px;

            .act {
                background: #2C57FF;
                color: white;
            }

            .btnpart button {
                height: 32px;
                width: 68px;
                line-height: 32px;
                padding: 0;

            }
        }

        .rangeinput {
            width: 252px;
            height: 32px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #E2E5EA;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;

            :deep(.select-line .el-input__inner) {
                border: none !important;
            }
        }

        .rangeinputtwo,
        .rangeinputthree {
            width: 252px;
            height: 32px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #E2E5EA;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;

            :deep(.select-line .el-input__inner) {
                border: none !important;
            }
        }

        .rangeinputborder {
            border: 1px solid #2C57FF;

        }


    }

    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            color: #474952;
            font-weight: bold;
        }

        .icon {
            width: 16px;
            height: 16px;
        }

        i {
            margin-right: 5px;
        }
    }

    .tablepart {
        min-height: 675px;
        background: white;
        margin-top: 10px;
        border-radius: 4px;
        padding: 16px 20px;

        .icon {
            margin-right: 8px;
        }

        .pagetab {
            text-align: right;
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
.printpart{
    #printArea {
        height: 200px;
        background-color: #fff;
        margin-top: 200px;
    }

    .printArea-title {
        font-size: 40px;
        font-weight: bold;
        position: relative;
        line-height: 60px;
        height: 60px;
        margin: 0;
        border: 0;
        text-align: center;
        color: #000 !important;
    /* margin-top: -500px; */
    }

    .printArea-title-img {
        height: 45px;
        float: left;
        margin: 0;
        border: 0;
        position: absolute;
        left: 0;
        color: #000 !important;
    }

    .printTable {
        width: 900px;
        border-collapse: collapse;
        text-align: center;
        table-layout: fixed;
        margin: 0;
        border: 0;
        color: #000 !important;
    }

    .printTable tbody tr td {
        border: 1px solid black;
        font-size: 24px;
        height: 50px;
        word-break: break-all;
        word-wrap: break-word;
        margin-top: -50px;
        color: #000 !important;
    }
}


// :deep(.el-input__inner) {
//     width: 127px !important;
// }
:deep(.el-input-group) {
    height: 32px !important;
    width: 331px !important;
    margin-top: 6px !important;
}

:deep(.el-cascader__search-input) {
    display: none !important;
}

:deep(.el-cascader__tags .el-tag) {
    max-width: 110px !important;
}

:deep(.select-line .el-input__inner) {
    box-sizing: border-box !important;
}

:deep(.el-cascader__dropdown) {
    width: 200px !important;
}

:deep(.el-cascader-menu__list) {
    width: 200px !important;
}

:deep(.el-cascader-node__label) {
    font-weight: 400 !important;
}

:deep(.el-input.is-focus .el-input__inner) {
    border-color: #2C57FF !important;
}

.fi {
    display: flex;
    align-items: center;
    color: #475B93;
    .icon {
        width: 7px;
        height: 16px;
        margin-left: 5px;
    }
    .acticon{
        transform: rotate(180deg);
    }
}
.fi:hover{
    color: #2C57FF !important;
}
</style>
<style lang="scss">
.rangeinput {
    .el-input__inner {
        border: none !important;
        width: 112px !important;
        background-color: transparent !important;
    }
}

.rangeinputtwo,
.rangeinputthree {
    .el-input__inner {
        border: none !important;
        width: 112px !important;
        background-color: transparent !important;
    }
}

.hb {
    margin-left: 20px;

    .el-input__inner {
        width: 200px !important;
    }

    .el-input__icon {
        line-height: 33px !important;
    }

    .el-input-group>.el-input__inner {
        width: 130px !important;
    }

    .el-select .el-input {
        width: 130px !important;
    }

    // .el-input-group {
    //     height: 32px !important;
    //     width: 331px !important;
    // }
}

.es {
    .el-select .el-input {
        width: 130px !important;
    }

    .el-input__inner {
        width: 130px !important;
    }
}

.len {
    .el-cascader-menu__list {
        width: 200px !important;
    }

}

.select-input {
    .el-input__inner {
        height: 32px !important;
        padding: 0 14px 0 146px !important;
        width: 320px !important;
    }

    .el-input--suffix {
        width: 133px !important;
    }

    .el-input__prefix {
        height: 100%;
        left: 0;

        .el-input__inner {
            width: 133px !important;
            padding: 0 12px !important;
        }
    }

    .el-input__suffix {
        display: flex;
        align-items: center;
    }

}

.lb {
    .el-form-item__label {
        display: none;
    }

    margin-left: 20px;
}

.zuback {
    .el-cascader-menu__wrap {
        height: 191px !important;

    }

    .el-cascader-panel {
        height: 187px !important;
    }



}
</style>