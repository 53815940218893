<template>
    <div class="addcontarctback">
        <el-dialog title="新建房屋回款" :visible.sync="showDialog" width="800px" class="addform" @close="cancel">
            <div class="addform contractbackformm long">
                <div class="formbox">
                    <el-form :model="addform" :rules="rules" ref="addform">
                        <el-row>
                            <el-col :span="11" class="left">
                                <el-form-item label="回款方式" prop="paymethod">
                                    <span slot="label"><span class="check-form-notice">*</span>回款方式</span>
                                    <el-select v-model="addform.paymethod" placeholder="请选择回款方式">
                                        <el-option v-for="item in hkfsList" :label="item.name" :value="item.id"
                                            :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="客户名称" prop="custom">
                                    <span slot="label"><span class="check-form-notice">*</span>客户名称</span>
                                    <el-select v-model="addform.custom" placeholder="请选择客户名称" filterable popper-class="custom-select" clearable>
                                        <el-option v-for="item in khList" :label="item.name" :value="item.id"
                                            :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="租赁合同" prop="leasecon">
                                    <span slot="label"><span class="check-form-notice">*</span>租赁合同</span>

                                    <el-input v-model="addform.leasecon_name" disabled :placeholder="addform.custom?'请选择':'请先选择客户'">
                                        <div slot="suffix" style="padding: 0 5px;line-height: 44px;" class="hover" @click="checkHouse">
                                            <local-svg iconClass="inputbox" style="width: 1.2rem;height: 1.1rem;"></local-svg>
                                        </div>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="房屋地址" prop="housearea">
                                    <span slot="label"><span class="check-form-notice">*</span>房屋地址</span>
                                    <el-tooltip :content="addform.housearea" placement="top" v-if="addform.housearea">
                                        <el-input disabled v-model="addform.housearea"
                                        :placeholder="addform.leasecon ? '请选择' : '选择房屋合同后可查看'"></el-input>
                                    </el-tooltip>
                                    <el-input v-else disabled v-model="addform.housearea"
                                        :placeholder="addform.leasecon ? '请选择' : '选择房屋合同后可查看'"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="所属部门" class="left" prop="department">
                                    <span slot="label"><span class="check-form-notice">*</span>所属部门</span>
                                    <el-select v-model="addform.department" placeholder="请选择所属部门">
                                        <el-option v-for="item in branchList" :label="item.name" :value="item.id"
                                            :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="备注" class="left">
                                    <el-input v-model="addform.notes" placeholder="请输入备注"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row >
                            <el-col style="width: 100%;text-align: right;">
                                <div class="info-header button-part">
                                    <div class="label"><span class="red">*</span>业务明细</div>
                                    <button class="cancel-icon hover" type="button" @click="addNewBus">                                        
                                        <local-svg iconClass="add" class="icon"></local-svg>
                                        新增业务
                                    </button>
                                </div>
                            </el-col>
                            <el-col style="width: 100%;text-align: left; padding-left: 76px; box-sizing: border-box;">
                                <div class="scorell-box" style="max-height: 350px;overflow-y: auto;">
                                    <div v-for="(item,index) in addform.type_list" :key="index" class="info-box" :class="{'last-box':index == addform.type_list.length - 1}">
                                        <div class="info-part">
                                            <div>
                                                <el-select v-model="item.businessde" placeholder="请选择业务明细" filterable  style="width: 250px !important;">
                                                    <el-option v-for="item in ywmxList" :label="item.name" :value="item.id"
                                                        :key="item.id"></el-option>
                                                </el-select>
                                            </div>
                                            <div style="margin-left: 10px;">
                                                <el-input v-model="item.paymoney" placeholder="请输入回款金额" @input="bigMoney(item)" @blur="changeThousand(item)"  style="width: 250px !important;"></el-input>
                                            </div>
                                        </div>
                                        <div class="info-part" style="margin-top: 12px;">
                                            <div>
                                                <el-date-picker v-model="item.date" type="daterange" range-separator="至"
                                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                                                </el-date-picker>
                                            </div>
                                            <div style="width: 250px !important;">
                                                <el-input disabled v-model="item.bigmoney"
                                                :placeholder="item.paymoney ? '请选择' : '输入回款金额后可查看'"></el-input>
                                            </div>
                                        </div>
                                        <div class="delete" v-if="index != 0" @click="deleteBus(index)">
                                            <local-svg iconClass="delete" class="icon handel-icon hover"></local-svg>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                            <!-- <div v-for="(item,index) in addform.type_list" :key="index">
                                <el-col style="width: 100%;text-align: right;" v-if="index == 0">
                                    <div class="button-part" style="box-sizing: border-box;padding: 0 18px;margin-top: 4px;">
                                        <button type="button" class="cancel-icon hover" @click="addNewBus">新增业务</button>
                                    </div>
                                </el-col>
                                <el-col :span="11">
                                    <el-form-item label="业务明细" prop="businessde" class="left">
                                        <span slot="label"><span class="check-form-notice">*</span>业务明细{{ index + 1 }}</span>
                                        <el-select v-model="item.businessde" placeholder="请选择业务明细" filterable>
                                            <el-option v-for="item in ywmxList" :label="item.name" :value="item.id"
                                                :key="item.id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="11" class="right">
                                    <el-form-item label="回款金额" prop="paymoney">
                                        <span slot="label"><span class="check-form-notice">*</span>回款金额{{ index + 1 }}</span>
                                        <el-input v-model="item.paymoney" placeholder="请输入回款金额" @input="bigMoney" @blur="changeThousand"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="11">
                                    <el-form-item label="日期范围" class="left" prop="starttime">
                                        <span slot="label"><span class="check-form-notice">*</span>日期范围{{ index + 1 }}</span>
                                        <el-date-picker v-model="addform.date" type="daterange" range-separator="至"
                                            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="11" class="right">
                                    <el-form-item label="大写金额" prop="bigmoney">
                                        <span slot="label"><span class="check-form-notice">*</span>大写金额{{ index + 1 }}</span>
                                        <el-input disabled v-model="addform.bigmoney"
                                            :placeholder="item.paymoney ? '请选择' : '输入回款金额后可查看'"></el-input>
                                    </el-form-item>
                                </el-col>
                            </div> -->
                        </el-row>
                        <el-row>
                            <el-col :span="11" class="right" style="margin-left: 0">
                                <el-form-item label="" class="long-input btnpart">
                                    <span slot="label"><span style="color:#F56C6C;position: absolute;left: -6px;">*</span>图片</span>
                                    <div class="file">
                                        <div class="filepart" style="position: relative;z-index: 99;">
                                            <el-upload class="upload-demo" :http-request="uploadFile" action="">
                                                <el-button class="uploadbtn"><local-svg iconClass="upload" class="icon"
                                                        plain></local-svg>上传图片</el-button>
                                            </el-upload>
                                            <div class="filetip"><span style="color: #F74B4B;">最多上传9张</span></div>
                                            <div class="filesize">支持扩展名：.jpg .jpeg .png，大小不超过9M</div>
                                        </div>
                                    </div>
                                </el-form-item>
                            </el-col>
                        <el-row>
                        </el-row>
                            <el-col :span="11" class="right" v-if="fileList.length > 0">
                                <div class="filelist">
                                    <div class="leftpart">
                                        <div class="left-btn hover change" @click="lastFile" v-show="actIndex != 0">
                                            <local-svg iconClass="swipericon" class="lefticon"></local-svg>
                                        </div>
                                    </div>
                                    <div class="fileline" v-for="(item, index) in showFile" :key="index">
                                        <div class="file-box">
                                            <img :src="item.url" alt="" class="image hover" @click="seeImageList(index)">
                                            <div class="close hover" @click.stop="delFile(index)">
                                                <local-svg iconClass="close" class="closeicon"></local-svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="rightpart">
                                        <div class="right-btn hover change" @click="nextFile"
                                            v-show="fileList.length > 4 && actIndex + 4 != fileList.length">
                                            <local-svg iconClass="swipericon" class="righticon"></local-svg>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </div>
            <div slot="footer" class="dialog-footer btnpart">
                <el-button class="confirm" type="primary" @click="addOk('addform', '1')">保存并提交</el-button>
                <el-button class="savebtn" type="primary" @click="addOk('addform', '2')">保存</el-button>
                <el-button class="cancel" @click="cancel">取消</el-button>
            </div>
        </el-dialog>
        <showImage :previewImg="imageData"></showImage>
        <HouseDialog :showdialog="houseDialog" v-if="houseDialog" :id="addform.custom" @cancel="closeHouse" :actarea="addform.housearea" :actuser="addform.leasecon" :actname="addform.leasecon_name" @add="getHouse"></HouseDialog>
    </div>
</template>
<script>
import service from "@/utils/request.js";
import { backsave,housecontract } from '../api'
import md5 from 'js-md5'
import { Loading } from 'element-ui';
import showImage from '@/components/showImage.vue'
import HouseDialog from '@/components/adddialog/housecontract.vue'
export default {
    components:{
        showImage,
        HouseDialog
    },
    props: {
        show: {
            type: Boolean,
            default: () => {
                return false
            }
        },
        ywmxList: {
            type: Array
        },
        hkfsList: {
            type: Array
        },
        khList: {
            type: Array
        },
        branchList: {
            type: Array
        },
        generateData:{
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            houseDialog:false,
            imageData:{
                imgList:[], // 所有图片数组
                index: 0, // 当前点击的图片的索引
                infinite: true,  // 是否可以循环切换
                popup: false // 弹窗的显示隐藏
            },
            actIndex: 0,
            showDialog: false,
            addform: {
                businessde: '',//业务明细
                paymethod: '',//回款方式
                paymoney: '',//回款金额
                bigmoney: '',//大写金额
                starttime: '',//开始日期
                endtime: '',//结束日期
                department: '',//所属部门
                notes: '',//备注
                custom: '',//客户名称
                leasecon: '',//租赁合同
                housearea: '',//房屋地址
                leasecon_name:'',
                type_list:[
                    {
                        businessde:'',
                        paymoney:'',
                        date:[],
                        bigmoney:'',
                    }
                ]
            },
            rules: {
                // businessde: [
                //     { required: true, message: '业务明细不能为空', trigger: 'blur' },
                // ],
                // paymethod: [
                //     { required: true, message: '回款方式不能为空', trigger: 'blur' },
                // ],
                // paymoney: [
                //     { required: true, message: '回款金额不能为空', trigger: 'blur' },
                // ],
                // starttime: [
                //     { required: true, message: '开始日期不能为空', trigger: 'blur' },
                // ],
                // endtime: [
                //     { required: true, message: '结束日期不能为空', trigger: 'blur' },
                // ],
                // department: [
                //     { required: true, message: '所属部门不能为空', trigger: 'blur' },
                // ],
                // custom: [
                //     { required: true, message: '客户名称不能为空', trigger: 'blur' },
                // ],
                // leasecon: [
                //     { required: true, message: '租赁合同不能为空', trigger: 'blur' },
                // ],
                // housearea: [
                //     { required: true, message: '房屋地址不能为空', trigger: 'blur' },
                // ],
                // department: [
                //     { required: true, message: '所属部门不能为空', trigger: 'blur' },
                // ],

            },
            fileList: [],
            showFile: [],
            ht:[]

        }
    },
    mounted(){
        this.addOk = this.$debounce(this.addOk )
        this.showDialog = this.show
        if(this.branchList.length==1){
            this.$set(this.addform,'department',this.branchList[0].id)
        }
        if(this.generateData.id){
            this.$set(this.addform,'custom',Number(this.generateData.id))
            this.$set(this.addform,'leasecon',this.generateData.leasecon)
            this.$set(this.addform,'leasecon_name',this.generateData.leasecon_name)
            this.$set(this.addform,'housearea',this.generateData.housearea)
            console.log(this.addform,111)
        }
    },
    methods: {
        changeThousand(item){
            if(item){
                item.paymoney = Number(item.paymoney.replace(/,/g, "")).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }else{
                this.addform.paymoney = Number(this.addform.paymoney.replace(/,/g, "")).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }
        },
        getHouse(msg){
            this.addform.leasecon = msg.id
            this.addform.leasecon_name = msg.name
            this.addform.housearea = msg.area_name
            this.houseDialog = false
        },
        addNewBus(){
            
            this.addform.type_list.push({
                businessde:'',
                paymoney:'',
                date:this.addform.type_list[0].date,
                bigmoney:'',
            })
        },
        deleteBus(index){
            this.addform.type_list.splice(index,1)
        },
        closeHouse(){
            this.houseDialog = false
        },
        checkHouse(){
            if(this.addform.custom){
               this.houseDialog = true 
            }else{
                this.$message.error('请先选择客户名称')
            }
        },
        seeImageList(index){
            this.imageData.popup = true
            this.imageData.imgList = this.fileList
            this.imageData.index = this.actIndex + index
        },
        cancel() {
            this.showDialog = false
            this.fileList = []
            this.showFile = []
            this.addform.leasecon_name = ''
            this.$refs.addform.resetFields();
            this.$emit('cancel', false)
            console.log(1)
        },
        getSellList() { },
        lastFile() {
            this.actIndex = this.actIndex - 1
            this.showFile = this.fileList.slice(this.actIndex, this.actIndex + 4)
        },
        nextFile() {
            this.actIndex = this.actIndex + 1
            this.showFile = this.fileList.slice(this.actIndex, this.actIndex + 4)
        },
        delFile(index) {
            this.fileList.splice(this.actIndex + index, 1)
            this.actIndex = this.actIndex == 0 ? this.actIndex : this.actIndex - 1
            this.showFile = this.fileList.slice(this.actIndex, this.actIndex + 4)
        },
        uploadFile(obj) {
            console.log(obj)
            let fileObj = obj.file
            const { size, name } = fileObj
            let md5code = md5(name + new Date().getTime())
            // console.log(md5code);
            let length = 500000
            let start = 0
            let end = 0
            let i = 1;
            Loading.service({
                text: "上传中...."
            });
            this.testHandle(md5code, length, start, end, size, name, fileObj, i)
        },
        testHandle(md5code, length, start, end, size, name, fileObj, i) {
            if (length > size) {
                length = size
            }
            if (end < size) {
                end = start + length
                if (end > size) {
                    end = size
                }
                let blob = ""
                blob = fileObj.slice(start, end)
                start = end
                let fd = new FormData()
                // console.log(blob);
                fd.append("file", blob)
                fd.append("md5code", md5code)
                let suc = 2
                if (end == size) {
                    suc = 1
                }
                fd.append("suc", suc)
                fd.append("tmp", i)
                fd.append("name", name)
                i = i + parseInt(1)
                service({
                    url: "/common/uploadImg",
                    method: "post",
                    data: fd,
                }).then(res => {
                    if (res.code == 200) {
                        if (suc == 2) {
                            this.testHandle(md5code, length, start, end, size, name, fileObj, i)
                        } else {
                            this.$message.success(res.msg)
                            Loading.service().close()
                            if (res.data.url !== undefined) {

                                this.fileList.push({ name: name, url: res.data.url })
                                if (this.showFile.length < 4) {
                                    this.showFile.push({ name: name, url: res.data.url })
                                }
                            }
                        }
                    } else {
                        this.$message.error(res.msg)
                        Loading.service().close()
                    }
                })
                    .catch(err => {
                        this.$message.error("请重新上传")
                        Loading.service().close()
                    })
            }
        },
        numToCapital(money) {
            var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');	 //汉字的数字
            var cnIntRadice = new Array('', '拾', '佰', '仟'); //基本单位
            var cnIntUnits = new Array('', '万', '亿', '兆');  //对应整数部分扩展单位
            var cnDecUnits = new Array('角', '分', '毫', '厘'); //对应小数部分单位
            var cnInteger = '整';	//整数金额时后面跟的字符
            var cnIntLast = '元';	//整数完以后的单位
            //最大处理的数字
            var maxNum = 999999999999999.9999;
            var integerNum;	 //金额整数部分
            var decimalNum;	 //金额小数部分
            //输出的中文金额字符串
            var chineseStr = '';
            var parts;		//分离金额后用的数组，预定义
            if (money == '') { return ''; }
        
            money = parseFloat(money);
            if (money >= maxNum) {
                //超出最大处理数字
                return '超出最大处理数字';
            }
            if (money == 0) {
                chineseStr = cnNums[0] + cnIntLast + cnInteger;
                return chineseStr;
            }
            //四舍五入保留两位小数,转换为字符串
            money = Math.round(money * 100).toString();
            integerNum = money.substr(0,money.length-2);
            decimalNum = money.substr(money.length-2);
            if(decimalNum.length == 1){
                decimalNum = '0' + decimalNum
            }
            //获取整型部分转换
            if (parseInt(integerNum, 10) > 0) {
                var zeroCount = 0;
                var IntLen = integerNum.length;
                for (var i = 0; i < IntLen; i++) {
                    var n = integerNum.substr(i, 1);
                    var p = IntLen - i - 1;
                    var q = p / 4;
                    var m = p % 4;
                    if (n == '0') {
                        zeroCount++;
                    } else {
                        if (zeroCount > 0) {
                            chineseStr += cnNums[0];
                        }
                        //归零
                        zeroCount = 0;
                        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                    }
                    if (m == 0 && zeroCount < 4) {
                        chineseStr += cnIntUnits[q];
                    }
                }
                chineseStr += cnIntLast;
            }
            //小数部分
            if (decimalNum != '') {
                var decLen = decimalNum.length;
                for (var i = 0; i < decLen; i++) {
                    var n = decimalNum.substr(i, 1);
                    if (n != '0') {
                        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                    }
                }
            }
            if (chineseStr == '') {
                chineseStr += cnNums[0] + cnIntLast + cnInteger;
            } else if (decimalNum == '' || /^0*$/.test(decimalNum)) {
                chineseStr += cnInteger;
            }
            return chineseStr;
        },
        bigMoney(item) {
            if(item){
                if(item.paymoney == ''){
                    item.bigmoney = ''
                    return
                }
                let number = Number(item.paymoney.replace(/,/g, ""))
                if(number && number>=0){
                    item.bigmoney = this.numToCapital(number)
                }else if(number && number<0){
                    item.bigmoney ='负' + this.numToCapital(Number(number)*-1)
                }
            }else{
                if(this.addform.paymoney == ''){
                    this.addform.bigmoney = ''
                    return
                }
                let number = Number(this.addform.paymoney.replace(/,/g, ""))
                if(number && number>=0){
                    this.addform.bigmoney = this.numToCapital(number)
                }else if(number && number<0){
                    this.addform.bigmoney ='负' + this.numToCapital(Number(number)*-1)
                }
            }
        },
        // getHouse(){
        //     let data = {
        //         c_id:this.addform.custom
        //     }
        //     housecontract(data).then(res =>{
        //         this.ht = res.data
        //     })
        // },
        // getlocal() {

        //     this.addform.housearea = this.addform.leasecon[1]
        // },
        async addOk(addform, type) {
            console.log(this.addform.type_list)
            if(this.addform.type_list.length == 0){
                this.$message.error('请先添加业务明细')
                return
            }
            let array = []
            this.addform.type_list.forEach(item =>{
                array.push({
                    business_detail:item.businessde,
                    start_time:item.date[0],
                    end_time:item.date[1],
                    pay_amount:item.paymoney.replace(/,/g, ""),
                })
            })
            let data = {
                // business_detail: this.addform.businessde,
                client: this.addform.custom,
                house_contract: this.addform.leasecon,
                // start_time: this.addform.starttime,
                // end_time: this.addform.endtime,
                pay_type: this.addform.paymethod,
                // pay_amount: this.addform.paymoney.replace(/,/g, ""),
                branch_id: this.addform.department,
                files: this.fileList,
                remark:this.addform.notes,
                bus_arr:array
            }
            if (type == 2) {
                data.audit_status = 15
                this.$loading({ text: '加载中...' });
                backsave(data).then(res=>{
                    if (res.code === 200) {
                        this.$emit('add', false)
                        this.fileList = [],
                            this.showFile = []
                        this.addform = {
                            businessde: '',//业务明细
                            paymethod: '',//回款方式
                            paymoney: '',//回款金额
                            bigmoney: '',//大写金额
                            starttime: '',//开始日期
                            endtime: '',//结束日期
                            department: '',//所属部门
                            notes: '',//备注
                            custom: '',//客户名称
                            leasecon: '',//租赁合同
                            housearea: '',//房屋地址
                            leasecon_name:''
                        },
                        this.$refs.addform.resetFields();
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                    this.$loading().close()
                }).catch(err => {
                    this.$message.error("请求超时")
                    this.$loading().close()
                })
            } else if (type == 1) {
                data.audit_status = 16
                this.$loading({ text: '加载中...' });
                backsave(data).then(res =>{
                    if (res.code === 200) {
                        this.$emit('add', false)
                        this.fileList = [],
                            this.showFile = []
                        this.addform = {
                            businessde: '',//业务明细
                            paymethod: '',//回款方式
                            paymoney: '',//回款金额
                            bigmoney: '',//大写金额
                            starttime: '',//开始日期
                            endtime: '',//结束日期
                            department: '',//所属部门
                            notes: '',//备注
                            custom: '',//客户名称
                            leasecon: '',//租赁合同
                            housearea: '',//房屋地址
                            leasecon_name:''
                        },
                        this.$refs.addform.resetFields();
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                    this.$loading().close()
                }).catch(err => {
                    this.$message.error("请求超时")
                    this.$loading().close()
                })
            }
        }
    },
    watch: {
        show: {
            handler(newValue, oldVal) {
                // console.log(1);
                if(this.showDialog){
                    console.log(this.branchList)
                    
                }
            }
        },

    },

}
</script>
<style lang="scss" scoped>
.addcontarctback {
    .headtab {
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 15px 0 0 0;

        .tab-box {
            display: flex;
            justify-content: space-between;
            width: 132px;
            font-size: 14px;
            border-bottom: 1px solid #F0F1F5;
            box-sizing: content-box;
            height: 25px;

            .tab {
                color: #B0B1B9;
                height: 100%;
            }

            .tab:hover {
                color: #2C57FF;
            }

            .acttab {
                font-weight: bold !important;
                color: #6B6D77 !important;
                border-bottom: 2px solid #2C57FF !important;
                width: auto;
                height: 100%;
            }
        }
    }

    .right {
        margin-left: 45px;

        .filelist {
            width: 100%;
            height: 95px;
            overflow-y: auto;
            padding: 15px 0px 0 0px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .fileline {
                .file-box {
                    width: 70px;
                    height: 70px;
                    position: relative;
                    margin-right: 8px;

                    .image {
                        width: 100%;
                        height: 100%;
                        border-radius: 4px;
                    }

                    .close {
                        position: absolute;
                        right: -7px;
                        top: -7px;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background: #E6E6E6;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .closeicon {
                            width: 8px;
                            height: 8px;
                            fill: #B0B1B9;
                        }
                    }

                    .close:hover {
                        background: rgba(44, 87, 255, 0.2) !important;

                        .closeicon {
                            fill: #2C57FF !important;
                        }
                    }
                }
            }

            .left-btn {
                width: 20px;
                height: 32px;
                border-radius: 4px 0px 0px 4px;
                background: rgba(0, 0, 0, 0.2);
                display: flex;
                align-items: center;
                justify-content: center;

                // margin-right: 4px;
                .lefticon {
                    width: 7px;
                    height: 12px;
                }
            }

            .right-btn {
                width: 20px;
                height: 32px;
                border-radius: 0px 4px 4px 0px;
                background: rgba(0, 0, 0, 0.2);
                display: flex;
                align-items: center;
                justify-content: center;

                // margin-left: -4px;
                .righticon {
                    width: 7px;
                    height: 12px;
                    transform: rotate(180deg);
                }
            }

            .change:hover {
                background: rgba(0, 0, 0, 0.3) !important;
            }

            .leftpart {
                width: 20px;
                margin-right: 4px;
            }

            .rightpart {
                width: 20px;
                margin-left: -4px;
            }
        }
    }

    .file {
        width: 100%;
        height: auto;

        .filepart {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .filetip {
                font-size: 10px;
                color: #D0D0D2;
                margin-left: 8px;
                box-sizing: border-box;
                padding-top: 8px;
            }

            .icon {
                margin-right: 7px;
            }

            .filesize {
                width: 100%;
                height: 12px !important;
                line-height: 12px;
                font-size: 12px;
                text-align: left;
                color: #D0D0D2;
            }
        }
    }

    .formbox {
        // height: 430px;

        .el-select .el-input .el-select__caret {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .table-main {
            padding: 14px 0;
            box-sizing: border-box;

            .table-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;

                .title {
                    font-weight: bold;
                    color: #6B6D77;
                }

                .addpart {
                    .icon {
                        fill: #475B93 !important;
                    }
                }

                .addpart:hover {
                    color: #2C57FF !important;

                    .svg-icon {
                        fill: #2C57FF !important;
                    }
                }

                .addpart:active {
                    color: #133CDC !important;

                    .svg-icon {
                        fill: #133CDC !important;
                    }
                }
            }

            .table {
                margin-top: 12px;
            }
        }
    }
    .info-header{
        width: 100%;
        display: flex;
        align-items: center;    
        margin-top: 8px;
        .label{
            font-size: 14px;
            color: #6B6D77;
            line-height: 32px;
            position: relative;
            .red{
                color: #FF5858;
                position: absolute;
                left: -5px;
            }
        }
        .cancel-icon{
            margin-left: 20px;
        }
    }
    .info-box{
        margin-top: 12px;
        width: 542px;
        height: auto;
        background: #FAFAFB;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #E2E7EE;
        box-sizing: border-box;
        padding: 16px 14px;
        position: relative;
        margin-bottom: 14px;
        .info-part{
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .icon{
            width: 16px;
            height: 16px;
            position: absolute;
            right: -28px;
            bottom: 46px;
        }

    }
    .last-box{
        margin-bottom: 0;
    }

    .dialog-footer {
        // margin-top: 30px;
    }
    .more-info{
        width: 100%;
        height: 20px;
        border: #6B6D77 1px solid;
    }
}

.handlerTab_tooltip {
    border: none !important;
    box-shadow: 0px 0px 16px 0 rgba(0, 0, 0, 0.1) !important;
    color: #6B6D77 !important;
    padding: 8px 16px !important;
    line-height: 20px !important;
}

.handlerTab_tooltip.is-light {
    border: none !important;
}

.handlerTab_tooltip .popper__arrow {
    /* 上方箭头 */
    border-top-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0, 0, 0, 0.1) !important;
    /* 下方箭头 */
    border-bottom-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0, 0, 0, 0.1) !important;
}


:deep(.el-form-item__label) {
    min-width: 77px !important;
}
</style>