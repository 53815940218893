<template>
    <div class="custom">
        <div class="border" v-if="!isDetail">
            <div class="top-search">
                <el-form :model="form" class="demo-form-inline" label-width="">
                    <div class="select-line bostrap contract" :style="{ height: (hide == false ? '43px' : 'auto') }" id="searchLine">
                        <el-form-item label="费用申请单号" class="">
                            <el-input v-model="form.number" placeholder="请输入">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="创建人">
                            <el-select v-model="form.create_people" placeholder="请选择" clearable multiple collapse-tags filterable>
                                <el-option v-for="item in adminList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所属部门" >
                            <el-cascader class="client_id longselect" placeholder="请选择" :options="branchList" collapse-tags v-model="form.branch"
                                :props="{ value: 'id',checkStrictly: true, label: 'label' }" filterable clearable  :show-all-levels="false">
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="支出类型">
                            <el-select v-model="form.business_type" placeholder="请选择" clearable @change="getdetailList" filterable>
                                <el-option v-for="item in singleList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="支出明细">
                            <el-select v-model="form.charge_detail" :placeholder="form.business_type?'请选择':'请先选择支出类型'" clearable :disabled="form.business_type?false:true" filterable>
                                <el-option v-for="item in detailList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="支出事由(新)" class="">
                            <el-input v-model="form.cause" placeholder="请输入">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="支出审核状态">
                            <el-select v-model="form.audit_status" placeholder="请选择" clearable >
                                <el-option v-for="item in typeList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="支出金额(元)" class="between">
                            <div class="rangeinput">
                                <el-input class="left" v-model="form.amount1" placeholder="请输入金额"></el-input>
                                至
                                <el-input class="right" v-model="form.amount2" placeholder="请输入金额"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="支出账户">
                            <el-select v-model="form.pay_account" placeholder="请选择" clearable multiple collapse-tags filterable>
                                <el-option v-for="item in paycountList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="实际支出时间" class="time">
                            <el-date-picker v-model="form.pay_time" value-format="yyyy-MM-dd" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" type="daterange" placeholder="请选择"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="票据情况">
                            <el-select v-model="form.bill_status" placeholder="请选择" clearable multiple collapse-tags>
                                <el-option v-for="item in billList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="创建时间" class="time">
                            <el-date-picker v-model="form.create_time" value-format="yyyy-MM-dd" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" type="daterange" placeholder="请选择"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="支出方式">
                            <el-select v-model="form.pay_type" placeholder="请选择" clearable multiple collapse-tags>
                                <el-option v-for="item in payList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="支出公司">
                            <el-select v-model="form.pay_company" placeholder="请选择" clearable multiple collapse-tags filterable>
                                <el-option v-for="item in companyList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="收款方" class="longest-input">
                            <el-select v-model="form.receive_name" placeholder="请选择" clearable multiple collapse-tags filterable>
                                <el-option v-for="item in receiveList" :label="item.name" :value="item.id" 
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="备注" class="">
                            <el-input v-model="form.remark" placeholder="请输入">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="收款账号" class="">
                            <el-input v-model="form.receive_account" placeholder="请输入">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="有无回款" class="">
                            <el-select v-model="form.has_back" placeholder="请选择" clearable>
                                <el-option v-for="item in hasList" :label="item.name" :value="item.id" 
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="line"></div>
                    <div class="btn-line">
                        <div class="btnpart">
                            <el-button class="confirm" @click="getList('search')" plain v-permission="'费用申请-select'">查询</el-button>
                            <el-button class="cancel" @click="clearSeach" plain v-permission="'费用申请-reset'">重置</el-button>
                        </div>
                        <div class="showpart hover" @click="showHide">
                            {{ actText }}
                            <local-svg iconClass="showdown" class="icon" :class="hide?'acticon':''"></local-svg>
                        </div>
                    </div>
                </el-form>
            </div>
            <div class="tablepart">
                <div class="top">
                    <div class="span">费用申请</div>
                    <div class="btnlist btnpart">
                        <el-button class="iconbtn" @click="deleteMoreUser" plain v-permission="'费用申请-batch_del'"><local-svg iconClass="delete"
                                class="icon"></local-svg>批量删除</el-button>
                        <el-button class="iconbtn" @click="showDialog = true" plain v-permission="'费用申请-list'"><local-svg iconClass="set" 
                                class="icon"></local-svg>设置列表展示列</el-button>
                        <el-button class="iconbtn" @click="exprotFile" plain v-permission="'费用申请-export'"><local-svg iconClass="expor"
                                class="icon"></local-svg>导出</el-button>
                        <el-button class="iconbtn" @click="beginAdd" plain v-permission="'费用申请-create'"><local-svg iconClass="add"
                                class="icon"></local-svg>新建</el-button>
                    </div>
                </div>
                <customTable v-if="headerList.length > 0" ref="dadtable" :list="tableData" :head="showHeader" @transfer="wantAdd"
                    @tableedit="wantEdit" @stopuser="setUserType" @deluser="deleteUser" @detail="seeDetail" @select="selectRow"
                    @submit="submitExamine" @confirm="confirmExamine" :loading="load">
                </customTable>
                <div v-else style="height:560px"></div>
                <div class="pagetab">
                    <div class="totalmoney">支出总金额合计<span>{{ totalMoney }}</span>元</div>
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <setshowDialog v-if="showDialog" :list="headerList" :show="showDialog" @show="setShow" @cancel="closeShow">
        </setshowDialog>
        <exportDialog v-if="exportDialog" :list="headerList" :show="exportDialog" @export="exportTable"
            @cancel="cancelExport"></exportDialog>
        <changeDialog v-if="changeDialog" :form="detailData" :show="changeDialog" @cancel="cancelChange" @add="addUser"
            :type="addtype" @edit="deitUser" ></changeDialog>
        <detailPart v-if="isDetail" @back="closeDetail" @print="printIt" :info="detailData" @add="addFollow" :follow="followList" :isDetail="isExamine"></detailPart>
        <addSelect v-if="followDialog" :show="followDialog" :list="headerList" @add="checkUser" @close="cancelFollow"></addSelect>
        <examineChange v-if="examineDialog" :show="examineDialog" :type="examineType" :form="billDetail" @submit="submitBill" @cancel="cancelBill"
        @examine="examineOk" @noexamine="examineBack"></examineChange>
        <!-- <iframe src='http://192.168.110.198/storage/image/16a8c947f3f5fdc640267a9984515462.jpg' frameborder="0" style="width:100% ; height: 700px"></iframe> -->
        <div class="printpart" v-show="isPrint">
            <section id="printArea" style="margin-top:60px;" v-if="printElem">
                <p class="printArea-title">
                    <img style="margin-left: 60px;" height="50" src="@/assets/image/costtext.png" alt="">
                    <img style="margin-left: -30px;" src="@/assets/image/printLogo.png" class="printArea-title-img" />
                </p>
                <div style="display:flex;justify-content:space-around;padding-bottom:5px; margin-top: 20px;">
                    <p style="color: #000 !important;">创建时间： {{ inputs.create_time }}</p>
                    <p style="color: #000 !important;">实际支出时间： {{ inputs.pay_time }}</p>
                    <p style="color: #000 !important;">支出审核状态： {{ inputs.audit_status_name }}</p>
                </div>
                <table class="printTable">
                    <tbody>
                    <tr>
                        <td colspan="2">费用申请单号</td>
                        <td colspan="3">{{ inputs.number }}</td>
                        <td colspan="2">所属部门</td>
                        <td colspan="3">{{ inputs.branch_name }}</td>
                        <td colspan="2">支出公司</td>
                        <td colspan="3">{{ inputs.pay_company }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">凭证</td>
                        <td colspan="3">{{ inputs.proof }}</td>
                        <td colspan="2">支出方式</td>
                        <td colspan="3">{{ inputs.pay_type }}</td>
                        <!-- <td colspan="2">票据情况</td>
                        <td colspan="3">{{ inputs.bill_status }}</td> -->
                        <td colspan="2">支出账户</td>
                        <td colspan="3">{{ inputs.pay_account }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">实际支出金额</td>
                        <td colspan="3">{{ inputs.amount }}</td>
                        <td colspan="2">支出类型</td>
                        <td colspan="3">{{ inputs.business_type }}</td>
                        <td colspan="2">支出明细</td>
                        <td colspan="3">{{ inputs.charge_detail }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">收款单位</td>
                        <td colspan="13">{{ inputs.receive_name }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">收款银行</td>
                        <td colspan="7">{{ inputs.bank }}</td>
                        <td colspan="2">收款账户</td>
                        <td colspan="4">{{ inputs.receive_account }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">支出事由</td>
                        <td colspan="13">{{ inputs.cause }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">对应回款明细</td>
                        <td colspan="13" style="box-sizing: border-box;padding: 15px 0;">
                            <div v-for="(item,index) in inputs.details" :key="index">
                                单号：{{ item.back_num }} &nbsp;&nbsp;&nbsp; 金额：{{item.back_amount}}；
                            </div>    
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">备注</td>
                        <td colspan="7">{{ inputs.remark }}</td>
                        <td colspan="2">制单人</td>
                        <td colspan="4">{{ inputs.create_name }}</td>
                    </tr>
                    </tbody>
                </table>
                <div style="display:flex;justify-content:space-around;padding-top:10px;">
                    <p style="color: #000 !important;">领导批示：范金伟</p>
                    <p style="color: #000 !important;">财务：耿玉兰</p>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import {constlist,headerlist,delconst,constdetail,addconst,editconst,selectlist,alluser,allbranch,receivelist,getbusselect,submitbill,billdetail,confirmexamine,examreturn,examine_edit,commonexamin} from '@/api/const'
import {saveheader} from '@/api/common'
import customTable from "@/components/custom/table.vue"
import setshowDialog from "@/components/custom/show.vue"
import exportDialog from "@/components/custom/export.vue"
import changeDialog from "@/components/constapply/change.vue"
import detailPart from "@/components/constapply/detail.vue"
import addSelect from "@/components/contract/follow.vue"
import returnCost from "@/components/constapply/return.vue"
import examineChange from "@/components/constapply/examine.vue"
import printJS from "print-js";

export default{
    name: 'custom',
    data() {
        return {
            examineDialog:false,
            receiveDialog:false,
            fileDialog:false,
            followDialog:false,
            showDialog: false,  //显示列表弹窗
            hide: false,
            exportDialog: false,
            changeDialog: false,
            isDetail: false,
            isExamine:false,
            returnDialog:false,
            load: false,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            totalMoney:0,
            actText: '展开',
            userList: [],
            branchList:[],   //部门列表
            adminList:[],    //负责人列表
            typeList: [],     //审核状态列表
            paycountList:[],  //支出账户列表
            billList:[],       //票据情况
            payList:[],    //付款方式
            companyList:[],    //支出公司
            singleList:[],   //业务类型
            detailList:[],   //业务明细
            receiveList:[],  //收款方列表
            partList: [],   
            exportData:{},
            form: {
                number:'',
                create_people:'',
                business_type:'',
                charge_detail:'',
                cause:'',
                audit_status:'',
                amount1:'',
                amount2:'',
                pay_time:[],
                pay_account:'',
                bill_status:'',
                create_time:[],
                pay_type:'',
                pay_company:'',
                receive_account:'',
                remark:'',
                receive_name:'',
                branch:[],
                has_back:''
            },
            tableData: [],
            showHeader: [],
            headerList: [],
            selectionList: [],
            tradeList: [],
            hasList:[{name:'有',id:1},{name:'无',id:2}],
            addtype: 1,
            detailData:{},
            followList:[],
            examineType:1,
            billDetail:{},
            examineId:'',
            load:true,
            printElem:false,
            inputs:{},
            isPrint:true
        }
    },
    components: {
        customTable,
        setshowDialog,
        exportDialog,
        changeDialog,
        detailPart,
        addSelect,
        returnCost,
        examineChange
    },
    watch: {
        '$route' (to, from) {
            console.log(to,from)
        }
    },
    mounted() {
        if(this.$route.query.id){
            if(this.$route.query.type && this.$route.query.type=='examine'){
                let data = {
                    id:this.$route.query.id
                }
                this.seeDetail(data)
                this.isExamine = true
            }
            if(this.$route.query.type && this.$route.query.type=='examinebill'){
                let data = {
                    id:this.$route.query.id
                }
                this.examineId = this.$route.query.id
                this.submitExamine(data)
            }
            if(this.$route.query.type && this.$route.query.type=='examineconfirm'){
                let data = {
                    id:this.$route.query.id
                }
                this.examineId = this.$route.query.id
                this.confirmExamine(data)
            }
            if(this.$route.query.type && this.$route.query.type=='detail'){
                let data = {
                    id:this.$route.query.id
                }
                this.seeDetail(data)
            }
        }
        if(this.$route.params.id){
            console.log(111)
            if(this.$route.params.type && this.$route.params.type=='examine'){
                let data = {
                    id:this.$route.params.id
                }
                this.seeDetail(data)
                this.isExamine = true
            }
            if(this.$route.params.type && this.$route.params.type=='examinebill'){
                let data = {
                    id:this.$route.params.id
                }
                this.examineId = this.$route.params.id
                this.submitExamine(data)
            }
            if(this.$route.params.type && this.$route.params.type=='examineconfirm'){
                let data = {
                    id:this.$route.params.id
                }
                this.examineId = this.$route.params.id
                this.confirmExamine(data)
            }
            if(this.$route.params.type && this.$route.params.type=='detail'){
                let data = {
                    id:this.$route.params.id
                }
                this.seeDetail(data)
            }
        }
        this.getSelect()
        this.getUserList()
        this.getBranchList()
        this.getReceiveList()
        this.getOutType()
        this.getHeaderList()
        this.getList()
    },
    methods: {
        printIt(msg){
            this.printElem = true;
            this.inputs = msg
            var that = this;
            setTimeout(() => {
                printJS({
                    style: ['@page { margin: 0cm 1.7cm 0cm 1.8cm;  margin-top: 1mm; margin-bottom: 1mm;}  body{margin-top: 60px;}'],
                    // style: [' @media print{@page {size:landscape}} @page { margin: 10px; } html { padding: 0 40px 0 40px; }  body{margin-top: 40px;}'], // @page { margin: 0px; } body {margin-top: 50px;}
                    printable: "printArea",
                    type: "html",
                    targetStyles: ['*'],
                    onLoadingEnd: function () {
                        that.printElem = false;
                    },
                    ignoreElements: ["ignore"],
                });
            }, 200);
        },
        examineBack(msg){
            let data = {
                b_id:this.examineId?this.examineId:msg.b_id,
                refuse:''
            }
            this.$loading({ text: '加载中...' });
            examreturn(data).then(res=>{
                if(res.code == 200){
                    this.examineDialog = false
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                    this.$router.push({
                        path:'/constapply'
                    })
                    this.getList()
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        examineOk(msg){
            let data = {
                b_id:this.examineId?this.examineId:msg.b_id,
            }
            this.$loading({ text: '加载中...' });
            confirmexamine(data).then(res=>{
                if(res.code == 200){
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                    this.examineDialog = false
                    this.$router.push({
                        path:'/constapply'
                    })
                    this.getList()
                }else{
                    this.$message.error(res.msg)
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        submitBill(msg){
            this.$loading({ text: '加载中...' });
            submitbill(msg).then(res=>{
                if(res.code == 200){
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                    this.examineDialog = false
                    this.getList()
                    this.$router.push({
                        path:'/constapply'
                    })
                }else{
                    this.$message.error(res.msg)
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        cancelBill(){
            this.examineDialog = false
        },
        submitExamine(msg){
            this.examineType = 1
            let data = {
                id: msg.id
            }
            billdetail(data).then(res => {
                this.billDetail = res.data
            })
            this.examineDialog = true
        },
        confirmExamine(msg){
            this.examineType = 2
            let data = {
                id: msg.id
            }
            billdetail(data).then(res => {
                this.billDetail = res.data
            })
            this.examineDialog = true
        },
        getdetailList(){
            let data = {
                id:this.form.business_type
            }
            getbusselect(data).then(res=>{
                this.detailList = res.data
            })
        },
        getOutType(){
            getbusselect().then(res=>{
                this.singleList = res.data
            })
        },
        getReceiveList(){
            receivelist().then(res=>{
                this.receiveList = res.data
            })
        },
        getUserList(){
            alluser().then(res=>{
                this.adminList = res.data
            })
        },
        getBranchList(){
            allbranch().then(res=>{
                this.branchList = res.data
            })
        },
        cancelFollow(){
            this.followDialog = false
        },
        checkUser(msg){
            this.followDialog = false
        },
        addFollow(){
            this.followDialog = true
        },
        getSelect() {
            selectlist().then(res => {
                this.typeList = res.data.option.audit_status
                this.paycountList = res.data.option.pay_account
                this.billList = res.data.option.bill_status
                this.payList = res.data.option.pay_type
                this.companyList = res.data.option.pay_company
            })
        },
        selectRow(row) {
            this.selectionList = row
            console.log(this.selectionList, 111)
        },
        deleteMoreUser() {
            if (this.selectionList.length == 0) {
                this.$message({
                    message: '请先选择要删除单据！',
                    type: 'error'
                })
                return
            }

            this.$confirm('此操作将永久删除所选单据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let array = []
                this.selectionList.forEach(item => {
                    array.push(item.id)
                })
                let data = {
                    b_id: array
                }
                delconst(data).then(res => {
                    if (res.code == 200) {
                        this.getList()
                        this.selectionList = []
                        this.changeDialog = false
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        deitUser(msg) {
            let data = {
                id:msg.id,
                audit_status:msg.audit_status,  
                amount:Number(msg.amount.replace(/,/g, "")),  
                business_type:msg.business_type,  
                charge_detail:msg.charge_detail,  
                pay_type:msg.pay_type,  
                branch_id:msg.branch_id,  
                pay_company:msg.pay_company, 
                is_channel:msg.is_channel, 
                proof:msg.proof,  
                pay_back_num:msg.pay_back_num,  
                remark:msg.remark,  
                b_a_id:msg.b_a_id,  
                fee_apply_details:msg.fee_apply_details,  
                cause:msg.cause,
                pay_account_id:msg.audit_status==66?msg.pay_account_id:'',
                pay_time:msg.audit_status==66?msg.pay_time:'',
                fee_apply_image:msg.fee_apply_image,
                bill_status:msg.bill_status,
                have:msg.have,
                bill_submit:msg.bill_status == 59 || msg.bill_status == 61 ? 0 : 1
            }
            for(var a = 0;a<data.fee_apply_details.length;a++){
                let item = data.fee_apply_details[a]
                item.backlist = []
                item.back_amount = item.back_amount?Number(item.back_amount.replace(/,/g, "")):''
                item.back_summary = item.back_summary?item.back_summary:''
                item.business_detail_id = item.business_detail
                item.business_type_id = item.business_type
                item.disburse_amount = item.disburse_amount?Number(item.disburse_amount.replace(/,/g, "")):''
                if(item.channel && item.channel!='无'){
                    item.channel_id = item.channel_id
                    item.return_amount = item.return_amount
                }
            }
            this.$loading({ text: '加载中...' });
            let role = localStorage.getItem('userrole') ? localStorage.getItem('userrole').split(',') : []
            let is_super = false
            role.forEach(item =>{
                if(item == 1 ){
                    is_super = true
                }
            })
            if(msg.audit_status == 63 && !is_super && msg.audit_status_name != '未提审' ){
                // let is_second = false
                // msg.audit_process.forEach(item =>{
                //     if(item.audit_status == 408){
                //         is_second = true
                //     }
                // })
                // if(is_second){
                examine_edit(data).then(res => {
                    if (res.code == 200) {
                        this.getList()
                        this.changeDialog = false
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                    this.$loading().close()
                }).catch(err => {
                    this.$message.error("请求超时")
                    this.$loading().close()
                })
                // }else{
                    // editconst(data).then(res => {
                    //     if (res.code == 200) {
                    //         this.getList()
                    //         this.changeDialog = false
                    //     } else {
                    //         this.$message({
                    //             message: res.msg,
                    //             type: 'error'
                    //         })
                    //     }
                    //     this.$loading().close()
                    // }).catch(err => {
                    //     this.$message.error("请求超时")
                    //     this.$loading().close()
                    // })
                
            // }else if(msg.audit_status == 63 && !is_super && msg.audit_status_name == '审核拒绝'){
            //     commonexamin(data).then(res => {
            //         if (res.code == 200) {
            //             this.getList()
            //             this.changeDialog = false
            //         } else {
            //             this.$message({
            //                 message: res.msg,
            //                 type: 'error'
            //             })
            //         }
            //         this.$loading().close()
            //     }).catch(err => {
            //         this.$message.error("请求超时")
            //         this.$loading().close()
            //     })
            }else{
                editconst(data).then(res => {
                    if (res.code == 200) {
                        this.getList()
                        this.changeDialog = false
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                    this.$loading().close()
                }).catch(err => {
                    this.$message.error("请求超时")
                    this.$loading().close()
                })
            }
            
        },
        exprotFile() {
            this.exportDialog = true
        },
        seeDetail(msg) {
            let data = {
                id: msg.id
            }
            constdetail(data).then(res => {
                this.detailData = res.data
                this.detailData.image.forEach(item =>{
                    item.img_url = item.image
                })
                this.isDetail = true
            })
        },
        beginAdd() {
            this.addtype = 1
            this.changeDialog = true
        },
        addUser(data) {
            for(var a = 0;a<data.fee_apply_details.length;a++){
                let item = data.fee_apply_details[a]
                item.backlist = []
                item.back_amount = item.back_amount?Number(item.back_amount.replace(/,/g, "")):''
                item.back_summary = item.back_summary?item.back_summary:''
                item.business_type = item.business_type?item.business_type:''
                item.business_detail = item.business_detail?item.business_detail:''
                if(item.channel && item.channel!='无'){
                    item.channel_id = item.channel_id
                    item.return_amount = item.return_amount
                }
                item.disburse_amount = item.disburse_amount?Number(item.disburse_amount.replace(/,/g, "")):''
                this.$delete(item,'backList')
            }
            data.amount = Number(data.amount.replace(/,/g, ""))
            this.$loading({ text: '加载中...' });
            addconst(data).then(res => {
                if (res.code == 200) {
                    this.getList()
                    this.changeDialog = false
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        getHeaderList() {
            let data = {
                id:5
            }
            headerlist(data).then(res => {
                this.showHeader = []
                this.headerList = res.data
                this.headerList.forEach(item => {
                    this.$set(item, 'check', item.is_show==1?true:false)
                    if(item.is_show==1){
                        this.showHeader.push(item)
                    }
                })
                this.$nextTick(() => {
                    this.$refs.dadtable.$refs.showTable.doLayout()
                })
            })
        },
        closeDetail() {
            this.getList()
            this.isDetail = false
        },
        cancelChange() {
            this.changeDialog = false
        },
        getSameKey(key,obj2){
            let equalKeys = ''
            // for (const key1 in obj1) {
                if (obj2.hasOwnProperty(key)) {
                    equalKeys = obj2[key]
                }
                console.log(key,111)
                console.log(obj2,2222)
            // }
            return equalKeys
        },
        exportTable(msg) {
            let typelist = ''
            let arr = []
            for(var a = 0; a<msg.length;a++){
                let item =  msg[a]
                if( a < msg.length-1){
                    typelist = typelist + `${item.prop}=${item.name}&`
                    
                }else{
                    typelist = typelist + `${item.prop}=${item.name}` 
                }
            }
            if(this.form.amount1!=='' && this.form.amount2!==''){
                arr.push(this.form.amount1)
                arr.push(this.form.amount2)
            }
            console.log(this.form.branch)
            let data = {
                search:this.form.number,
                create_id:this.form.create_people.toString(),
                branch:this.form.branch[this.form.branch.length-1]?this.form.branch[this.form.branch.length-1]:'',
                business_type:this.form.business_type,
                charge_detail:this.form.charge_detail,
                cause: this.form.cause,
                audit_status:this.form.audit_status,
                amount:arr.toString(),
                pay_account:this.form.pay_account,
                pay_time:this.form.pay_time.toString(),
                bill_status:this.form.bill_status,
                time_range:this.form.create_time.toString(),
                pay_type:this.form.pay_type,
                pay_company:this.form.pay_company,
                b_a_id:this.form.receive_name,
                remark:this.form.remark,
                collect_account:this.form.receive_account,
            }
            console.log(typelist)
            let string = `search|${data.search};create_id|${data.create_id};branch|${data.branch};business_type|${data.business_type};charge_detail|${data.charge_detail};cause|${data.cause};audit_status|${data.audit_status};amount|${data.amount};pay_account|${data.pay_account};pay_time|${data.pay_time};bill_status|${data.bill_status};time_range|${data.time_range};pay_type|${data.pay_type};pay_company|${data.pay_company};b_a_id|${data.b_a_id};remark|${data.remark};collect_account|${data.collect_account}`
            //  ${typelist}&
            console.log(string)
            window.open(`${this.$BASE_URL}feeapply/exportData?${typelist}&token=${localStorage.getItem('token')}&export=${string}`,'_blank')
            // window.open(`http://192.168.110.48/feeapply/exportData?${typelist}&token=${localStorage.getItem('token')}&export=${string}`,'_blank')
            
            this.exportDialog = false
        },
        cancelExport() {
            this.exportDialog = false
        },
        // 设置展示列保存&取消
        setShow(msg) {
            let ids = []
            msg.forEach(item =>{
                ids.unshift(item.id)
            })
            let data = {
                id:ids.toString(),
                type:5
            }
            saveheader(data).then(res =>{
                if(res.code == 200){
                    this.$message.success(res.msg)
                    this.getHeaderList()
                    this.showHeader = msg
                    this.showDialog = false
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        closeShow(msg) {
            this.showDialog = false
        },
        bindUser() { },
        clearSeach() {
            this.form = {
                number:'',
                create_people:'',
                business_type:'',
                charge_detail:'',
                cause:'',
                audit_status:'',
                amount1:'',
                amount2:'',
                pay_time:[],
                pay_account:'',
                bill_status:'',
                create_time:[],
                pay_type:'',
                pay_company:'',
                receive_account:'',
                has_back:"",
                remark:'',
                receive_name:'',
                branch:[]
            }
            this.getList('search')
        },
        deleteUser(msg) {
            let data = {
                b_id: [msg]
            }
            delconst(data).then(res => {
                if (res.code == 200) {
                    this.getList()
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        wantAdd() { },
        // 获取用户列表
        getList(e) {
            // let client = []

            // this.form.client.forEach(item => {
            //     client.push(item[0])
            // })
            if(e){
                this.currentPage = 1
            }
            this.load = true
            let arr = []
            if(this.form.amount1!=='' && this.form.amount2!==''){
                arr.push(this.form.amount1)
                arr.push(this.form.amount2)
            }
            let data = {
                page: this.currentPage,
                count: this.pageSize,
                search: this.form.number,
                business_type: this.form.business_type,
                charge_detail: this.form.charge_detail,
                cause: this.form.cause,
                amount: arr,
                pay_time: this.form.pay_time,
                pay_account: this.form.pay_account,
                bill_status: this.form.bill_status,
                time_range: this.form.create_time,
                pay_type: this.form.pay_type,
                pay_company: this.form.pay_company,
                receive_account: this.form.receive_account,
                has_back:this.form.has_back,
                remark: this.form.remark,
                receive_name: this.form.receive_name.toString(),
                branch: this.form.branch[this.form.branch.length-1],
                create_id:this.form.create_people.toString(),
                audit_status: this.form.audit_status
            }
            constlist(data).then(res => {
                this.tableData = res.data.table
                this.tableData.forEach(item =>{
                    this.$set(item,'channel',item.channel && item.channel.length > 0 ? item.channel.join('、'):'无')
                    this.$set(item,'source',item.source && item.source.length > 0 ? item.source.join('、'):'无')
                })
                this.total = res.data.total
                this.totalMoney = res.data.total_amount
                setTimeout(() => {
                    this.load = false
                }, 1000);
            })
        },
        wantEdit(msg) {
            let data = {
                id: msg.info.id
            }
            constdetail(data).then(res => {
                this.addtype = 2
                this.detailData = res.data
                this.changeDialog = true
            })
        },
        setUserType() { },
        showHide() {
            this.actText = this.hide ? '展开' : '收起'
            this.hide = !this.hide
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.currentPage = 1
            this.getList()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getList()
        },
    }
}

</script>

<style src="@/assets/scss/formstyle.scss" lang="scss" ></style>
<style lang="scss" scoped>
* {
    box-sizing: content-box;
}

.custom {
    .border{
        padding: 24px;
    }
    .top-search {
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;

        .select-line {
            display: flex;
            padding: 0 20px 0 0;
        }

        .line {
            width: 100%;
            height: 1px;
            background: #F0F1F5;
        }

        .btn-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 11px 20px;

            .showpart {
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #474952;
                .icon {
                    margin-left: 6px;
                    width: 7px;
                    height: 16px;
                }
                .acticon{
                    transform: rotate(180deg);
                }
            }
            .showpart:hover{
                color: #2C57FF !important;
            }
            .act {
                background: #2C57FF;
                color: white;
            }

            .btnpart button {
                height: 32px;
                width: 68px;
                line-height: 32px;
                padding: 0;

            }
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
            font-weight: bold;
            color: #474952;
            line-height: 16px;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .icon {
            width: 16px;
            height: 16px;
        }

        i {
            margin-right: 5px;
        }
    }

    .tablepart {
        // min-height: 658px;
        background: white;
        margin-top: 10px;
        border-radius: 4px;
        padding: 16px 20px;

        .icon {
            margin-right: 8px;
        }

        .pagetab {
            // text-align: right;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            .totalmoney{
                font-size: 14px;
                color: #B0B1B9;
                span{
                    margin: 0 8px;
                    color: #2C57FF;
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }

        .span {
            font-size: 14px;
            font-weight: bold;
            color: #474952;
        }

        .top {
            display: flex;
            align-items: center;
        }
    }
    .iframe-body-sty{position: relative;overflow: hidden;height:600px;width: 1200px;background-color: red;}
	.iframe-body-sty>#iframe-shrink{position: absolute;transform:scale(0.55);left: -400px;top: -170px;}
    .printpart{
        #printArea {
            height: 200px;
            background-color: #fff;
        }

        .printArea-title {
            font-size: 33px;
            font-weight: bold;
            position: relative;
            line-height: 60px;
            text-align: center;
            height: 60px;
        }

        .printArea-title-img {
            height: 45px;
            float: left;
            position: absolute;
            left: 2vw;
            top: 7px;
        }

        .printTable {
            width: 900px;
            border-collapse: collapse;
            text-align: center;
            table-layout: fixed;
        }

        .printTable tbody tr td {
            border: 1px solid black;
            font-size: 24px;
            height: 40px;
            word-break: break-all;
            word-wrap: break-word;
            color: #000 !important;
        }
    }
}

</style>