<template>
    <div class="contractdetail">
        <div class="banner-border" v-if="!isDetail">
            <div class="top-banner">
                <div class="background"
                    :style="{ 'background': 'url(' + image + ') no-repeat', 'backgroundSize': '1012px 100%', 'backgroundPosition': 'right', 'opacity': '0.9' }">
                    <div class="banner-left">
                        <div class="title">回款单号：{{ info.number }}
                            <div class="tip status1" v-if="info.type==42"><local-svg iconClass="status1"></local-svg>未提审</div>
                            <div class="tip status2" v-if="info.type==43"><local-svg iconClass="status2"></local-svg>审核中</div>
                            <div class="tip status3" v-if="info.type==44"><local-svg iconClass="status3"></local-svg>审核同意</div>
                            <div class="tip status4" v-if="info.type==45"><local-svg iconClass="status4"></local-svg>审核拒绝</div>
                            <div class="tip status5" v-if="info.type==46"><local-svg iconClass="status5"></local-svg>财务已确认</div>
                        </div>
                        <div class="message">
                            <div class="line" :class="{ 'active': showWidth < 1350 }">客户名称：<span>{{ info.client }}</span></div>
                            <div class="line" :class="{ 'active': showWidth < 1350 }">创建人：<span>{{ info.admin }}</span></div>
                        </div>
                    </div>
                    <div class="banner-right">
                        <button class="print hover" @click="toPrint">
                            <local-svg iconClass="print"></local-svg>
                            <span>打印</span>
                        </button>
                        <!--  -->
                        <button class="print hover" @click="generateBill" v-if="!info.releate_invoice && info.type == 46  || info.type == 46 && info.releate_invoice && info.releate_invoice.audit_status == 20">
                            <local-svg iconClass="generate"></local-svg>
                            <span>一键开票</span>
                        </button>
                        <button class="btn hover" @click="goBack">
                            <local-svg iconClass="back"></local-svg>
                            <span>返回上一页</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="examine-banner">
            <div class="examine-header">
                <div class="header-left">
                    <local-svg icon-class="back" class="icon handel hover" @click.native="backList"></local-svg>
                    <div class="title">回款单号：{{ info.number }}</div>
                </div>
                <div class="header-right btnpart" v-if="isCheck">
                    <el-button class="confirm" plain @click="financeCheck" v-if="info.type == 44" v-permission="'回款记录-finance'">财务审核</el-button>
                    <el-button class="cancel" plain @click="refuseCheck" v-if="info.type == 44" v-permission="'回款记录-finance'">审核拒绝</el-button>
                    <el-button class="confirm" plain @click="agreeCheck" v-if="info.type == 43" v-permission="'回款记录-agree'">审核同意</el-button>
                    <el-button class="cancel" plain @click="refuseCheck" v-if="info.type == 43" v-permission="'回款记录-agree'">审核拒绝</el-button>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="descript" :class="{'auto-detail-part':!isDetail,'auto-detail-examine-part':isDetail}">
                <!-- <div class="header">
                    <div class="tab hover" v-for="(item,index) in tabList" :class="{'acttab':actTab==index}" :key="index" @click="changeTab(index)">
                        {{ item.name }}
                    </div>
                </div> -->
                <div class="descript-main">
                    <el-descriptions title="详细资料" :column="2" border v-if="actTab==0">
                        <el-descriptions-item v-if="isDetail" label="客户名称">{{ info.client  ? info.client  : '/' }}</el-descriptions-item>
                        <el-descriptions-item v-if="isDetail" label="负责人">{{ info.branch  ? info.branch  : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="回款总额（元）">{{ info.amount  ? Number(info.amount).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })  : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="回款方式">{{ info.back_type  ? getReturn(showList1,info.back_type)  : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="合同单号">{{ info.contract   ? "HT#0000" + info.contract  : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="合同时间">{{ info.contract_time  ? info.contract_time  : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="系统来源">{{ info.source  ? info.source  : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="渠道名称">{{ info.channel  ? info.channel  : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="支出金额">{{ info.fee_apply_amount  ? info.fee_apply_amount  : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="支出差额">{{ info.differ_amount  ? info.differ_amount  : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="合作类型">{{ info.team_type  ? info.team_type  : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="开票单号">{{ info.bill_num  ? info.bill_num  : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="回款审核状态">{{ info.type==42? '未提交':info.type==43? '审核中':info.type==44? '审核同意':info.type==45? '审核拒绝': '财务已确认'}}</el-descriptions-item>
                        <el-descriptions-item label="回款日期" >{{ info.back_time  ? this.$date1(info.back_time).split(' ')[0]  : '/'}}</el-descriptions-item>
                        <el-descriptions-item label="实际到账日期" >{{ info.real_time ? info.real_time : '/'}}</el-descriptions-item>
                        <el-descriptions-item label="开始日期" >{{ info.start_time ? info.start_time : '/'}}</el-descriptions-item>
                        <el-descriptions-item label="到账账户">{{ info.get_account  ? getReturn(showList2,info.get_account)  : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="结束日期">{{ info.end_time ? info.end_time : '/'}}</el-descriptions-item>
                        <el-descriptions-item label="回款摘要">{{ info.summary  ? info.summary : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="创建时间" >{{ info.create_time ? info.create_time : '/'}}</el-descriptions-item>
                        <el-descriptions-item label="所属部门">{{ info.branch  ? info.branch : '/' }}</el-descriptions-item>
                        <!-- <el-descriptions-item label="业务类型">{{ info.business_type ? info.business_type : '/' }}</el-descriptions-item> -->
                        <!-- <el-descriptions-item label="业务明细">{{ info.business_detail ? info.business_detail : '/'}}</el-descriptions-item> -->
                        <el-descriptions-item label="客户微信">{{ info.wechat && info.wechat == 1 ?  '是' : info.wechat == 0 ?  '否' : '/'}}</el-descriptions-item>
                        <el-descriptions-item label="审批意见">{{ info.type==45 && info.refuse ? info.refuse :info.type==46 && info.finance_opinion ? info.finance_opinion : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="备注" :span="2">{{ info.remark ? info.remark : '/'}}</el-descriptions-item>
                        <el-descriptions-item label="照片" :span="2">
                            <div class="image">
                                <div class="addimg-box">
                                    <div class="showimage" v-if="info.imgArr.length>0" :style="{'background': 'url(' + info.imgArr[0].img_url + ') no-repeat','backgroundSize':'100% 100%'}">
                                        <!-- <img :src="'http://test.tjlhyg.com/'+info.imgArr[0].img_url" alt="" > -->
                                        <div class="tip hover" @click="seeImage">点击查看大图</div>
                                    </div>
                                    <div class="img-total" v-if="info.imgArr.length>0">共{{ info.imgArr.length }}张</div>
                                    <span v-else>无</span>
                                </div>
                            </div>
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
                <div class="table-part">
                    <div class="table-header">
                        单项明细 
                    </div>
                    <el-table class="table" :data="tableData" height="100%" style="width: 100%" :header-cell-style="{ 'background': '#F4F7FC', 'color': '#B0B1B9', 'fontWeight': 'bold' }" tooltip-effect="light"  ref="showTable">
                        <el-table-column type="index" width="80" align="center" label="序号"></el-table-column>
                        <el-table-column width="" label="单项明细" prop="single"></el-table-column>
                        <el-table-column width="" label="业务类型" prop="bus_type"></el-table-column>
                        <el-table-column width="" label="业务明细" prop="bus_detail"></el-table-column>
                        <el-table-column width="" label="回款金额" prop="amount"></el-table-column>
                    </el-table>
                    <div class="table-tip">
                        合计回款：<span style="color: #2C57FF;">{{ info.total_money }}</span> / {{ info.total_money_big }}
                    </div>
                </div>
                <!--  -->
                <div class="examinebtn common-examinebtn" >
                    <div class="header-right btnpart " v-if="isCheck&&info.handle&&!isDetail">
                        <el-button class="confirm" plain @click="financeCheck" v-if="info.type == 44" v-permission="'回款记录-finance'">财务审核</el-button>
                        <el-button class="cancel" plain @click="refuseCheck" v-if="info.type == 44" v-permission="'回款记录-finance'">审核拒绝</el-button>
                        <el-button class="confirm" plain @click="agreeCheck" v-if="info.type == 43" v-permission="'回款记录-agree'">审核同意</el-button>
                        <el-button class="cancel" plain @click="refuseCheck" v-if="info.type == 43" v-permission="'回款记录-agree'">审核拒绝</el-button>
                    </div>
                </div>
            </div>
            <div class="detail-right">
                <div class="timetree">
                    <div class="timetitle">
                        <local-svg iconClass="cortanctdetail" class="icon"></local-svg>回款审批进度
                    </div>
                    <div class="tree">
                        <el-timeline>
                            <el-timeline-item
                            v-for="(item, index) in info.audit_process" :key="index" >
                                <div class="tree-info" :class="{'tree5':item.audit_status==43&&item.create_time,'tree1':item.audit_status==44,'tree3':item.audit_status==45,'tree2':item.audit_status==46,'tree4':item.audit_status==43&&item.audit=='审核中',}">
                                    <div class="timeline">
                                        <div class="date">{{ item.create_time?item.create_time:'-' }}</div>
                                        <div class="status">{{ item.audit }}</div>
                                    </div>
                                    <div class="checkuser">{{item.name}}</div>
                                    <div class="reason" v-if="item.audit_reject">
                                        <span class="reason-title">拒绝原因：</span>
                                        {{ item.audit_reject }}
                                    </div>
                                </div>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </div>
                <div class="user">
                    <div class="table-title">
                        <span class="title">
                            <local-svg iconClass="detailuser" class="icon"></local-svg>跟进人</span>
                        <div class="addpart handel hover" @click="addUser">
                            <local-svg iconClass="add" class="icon"></local-svg>
                            添加
                        </div>
                    </div>
                    <div class="userlist">
                        <el-empty :image-size="100" :image="image2" v-if="info.follower.length==0" description="暂无跟进人，点击添加按钮操作"></el-empty>
                        <div class="fileline hover" v-for="(item,index) in info.follower" :key="index">
                            <div>
                                <span class="name">{{ item.name }}</span>
                            </div>
                            <div class="closepart">
                                <span>{{ item.type }}</span>
                                <div class="close-box" @click="deleteFollow(item,index)">
                                    <div class="close">
                                        <local-svg iconClass="close" class="closeicon"></local-svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <refuseExamine v-if="refuseDialog" :show="refuseDialog" @close="cancelRefuse" @examine="confirmRefuse"></refuseExamine>
        <backAgree v-if="financeDialog" :show="financeDialog" @close="financeCancel" @examine="financeConfirm"></backAgree>
        <showImage :previewImg="imageData"></showImage>
    </div>
</template>

<script>
// import {addfollow,delfollow} from '@/api/contarct'
import {addfollow,delfollow,financeexamine,examine,backdetail} from '@/api/contanctback'
import { scrm_wechat } from '@/api/scrm_custom'
import backfollow from '@/assets/js/backfollow'
import backclose from '@/assets/js/backclose'
import backAgree from '@/components/examine/backagree.vue'
import refuseExamine from '@/components/examine/refuse.vue'
import showImage from '@/components/showImage.vue'
export default {
    components: {
        refuseExamine,
        backAgree,
        showImage
    },
    name: "detail",
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        info: {
            type: Object,
            default: () => {
                return {}
            }
        },
        follow:{
            type:Array,
        	default:()=>{
        		return []
        	}
        },
        isDetail:{
            type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        showList1:{
            type: Array,
            default: () => {
                return []
            }
        },
        showList2:{
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            isCheck:true,
            financeDialog:false,
            refuseDialog:false,
            activities: [{
                date:'2023-03-08  14:45',
                status:1,
                user:'审批人'
            },{
                date:'2023-03-08  14:45',
                status:2,
                user:'审批人'
            },{
                date:'2023-03-08  14:45',
                status:3,
                user:'审批人'
            },{
                date:'2023-03-08  14:45',
                status:4,
                user:'审批人',
                reason:'合同金额与纸质合同金额不一致，这是一个文字较多的字段，这是一个文字较多的字段。'
            },],
            image1:require('../../assets/image/none.png'),
            image2:require('../../assets/image/noneuser.png'),
            tableData:[],
            tradeList:[],
            tabList:[
                {name:'详情资料'},
                {name:'合同单项'}
            ],
            fileList:[
            ],
            actTab:0,
            image: require('../../assets/image/detail.png'),
            ischange: false,
            showWidth: 0,
            imageData:{
                imgList:[], // 所有图片数组
                index: 0, // 当前点击的图片的索引
                infinite: true,  // 是否可以循环切换
                popup: false // 弹窗的显示隐藏
            },
            backfollowListener:null
        };
    },
    mounted() {
        let that = this
        window.onresize = () => {
            return (() => {
                that.showWidth = document.body.clientWidth
            })()
        },
        this.getFollow()
        this.getWechat(that.info)
        this.tableData = this.info.pay_back_single
        let total = 0
        this.tableData.forEach(item =>{
            item.bus_type = item.contract_single.bus_type
            item.bus_detail = item.contract_single.bus_detail
            item.single = item.contract_single.name
            total = Number(item.amount) + total
            item.amount = item.amount + '/' +this.bigMoney(item.amount)
        })
        this.info.total_money = total.toFixed(2)
        this.info.total_money_big = this.bigMoney(total)
    },
    methods: {
        toPrint(){
            this.$emit('print',this.info)
        },
        generateBill(){
            if(this.info.releate_invoice && this.info.releate_invoice.audit_status != 20){
                this.$message.error('此回款已开票，请问重复操作！')
                return
            }
            this.$router.push({
                name:'openbill',
                params:{
                    type:'generate',
                    way:1,
                    client:this.info.cl_id,
                    client_name:this.info.client,
                    id:this.info.id,
                    amount:this.info.amount,
                    channel:this.info.channel,
                    source:this.info.source
                }
            })
        },
        seeImage(){
            this.imageData.popup = true
            this.imageData.imgList = this.info.imgArr
        },
        financeCheck(){
            this.financeDialog = true
        },
        financeConfirm(msg){
            let data = {
                id:this.info.id,
                audit_status:46,
                get_account:msg.get_account,
                real_time:msg.real_time,
                finance_opinion:msg.finance_opinion
            }
            this.$loading({ text: '加载中...' });
            financeexamine(data).then(res=>{
                if(res.code == 200){
                    this.seeDetail(this.info.id)
                    this.financeDialog = false
                    this.isCheck = false
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        financeCancel(){
            this.financeDialog = false
        },
        cancelRefuse(){
            this.refuseDialog = false
        },
        confirmRefuse(msg){
            let data = {
                id:this.info.id,
                audit_status:45,
                refuse:msg.reason
            }
            this.$loading({ text: '加载中...' });
            examine(data).then(res=>{
                if(res.code == 200){
                    this.seeDetail(this.info.id)
                    this.refuseDialog = false
                    this.isCheck = false
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        getWechat(item){
            let data = {
                client_id:item.cl_id,
                token:localStorage.getItem('token')
            }
            scrm_wechat(data).then(res =>{
                this.$set(this.info,'wechat',res.data.bind_type )
            })
        },
        seeDetail(msg) {
            let data = {
                id:msg
            }
            this.$emit('reload',data)
            // let data = {
            //     id: msg
            // }
            // backdetail(data).then(res => {
            //     this.info = res.data
            //     let obj = JSON.parse(JSON.stringify(this.info.contract))
            //     this.$set(this.info,'contract',obj.contract.id)
            //     this.info.channel = obj.contract.sell_info && obj.contract.sell_info.channel ? obj.contract.sell_info.channel : '暂无'
            //     this.info.source = obj.contract.sell_info && obj.contract.sell_info.source ? obj.contract.sell_info.source : '暂无'
            // })
        },
        agreeCheck(){
            let data = {
                id:this.info.id,
                audit_status:44,
                refuse:''
            }
            this.$loading({ text: '加载中...' });
            examine(data).then(res=>{
                if(res.code == 200){
                    this.seeDetail(this.info.id)
                    this.isCheck = false
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        refuseCheck(){
            this.refuseDialog = true
        },
        deleteFollow(item,index){
            this.$confirm('此操作将删除此跟进人, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id:item.id
                }
                delfollow(data).then(res=>{
                    if(res.code == 200){
                        this.info.follower.splice(index,1)
                        this.$message({
                            message:res.msg,
                            type:'success'
                        })
                    }else{
                        this.$message({
                            message:res.msg,
                            type:'error'
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        getFollow() {
            this.backfollowListener = value => {
                console.log(this.info.id,value)
                let data = {
                    id:this.info.id,
                    follower:value.id
                }
                addfollow(data).then(res=>{
                    if( res.code == 200 ){
                        backclose.$emit("closefollow",true)
                        this.info.follower.push(value)
                        this.$message({
                            message:res.msg,
                            type:'success'
                        })
                    }else{
                        this.$message({
                            message:res.msg,
                            type:'error'
                        })
                    }
                })
                
            }
            backfollow.$on('backfollow', this.backfollowListener);
        },
        handleClose(){},
        addUser(){
            this.$emit('add',true)
        },
        addDetail(){},
        changeTab(index){
            this.actTab = index
        },
        goBack() {
            if (this.$route.query.id) {
                // console.log(1111);
                this.$router.go(-1)
            } else {
                this.$emit('back', false)

            }
        },
        backList(){
            this.$router.push({path:'/todolist'})
        },
        getReturn(array,key){
            let value = ''
            array.forEach(item =>{
                if(item.id == key){
                    value = item.name
                }
            })
            return value
        },
        numToCapital(money) {
            var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');	 //汉字的数字
            var cnIntRadice = new Array('', '拾', '佰', '仟'); //基本单位
            var cnIntUnits = new Array('', '万', '亿', '兆');  //对应整数部分扩展单位
            var cnDecUnits = new Array('角', '分', '毫', '厘'); //对应小数部分单位
            var cnInteger = '整';	//整数金额时后面跟的字符
            var cnIntLast = '元';	//整数完以后的单位
            //最大处理的数字
            var maxNum = 999999999999999.9999;
            var integerNum;	 //金额整数部分
            var decimalNum;	 //金额小数部分
            //输出的中文金额字符串
            var chineseStr = '';
            var parts;		//分离金额后用的数组，预定义
            if (money == '') { return ''; }

            money = parseFloat(money);
            if (money >= maxNum) {
                //超出最大处理数字
                return '超出最大处理数字';
            }
            if (money == 0) {
                chineseStr = cnNums[0] + cnIntLast + cnInteger;
                return chineseStr;
            }
            //四舍五入保留两位小数,转换为字符串
            money = Math.round(money * 100).toString();
            integerNum = money.substr(0, money.length - 2);
            decimalNum = money.substr(money.length - 2);
            if (decimalNum.length == 1) {
                decimalNum = '0' + decimalNum
            }
            //获取整型部分转换
            if (parseInt(integerNum, 10) > 0) {
                var zeroCount = 0;
                var IntLen = integerNum.length;
                for (var i = 0; i < IntLen; i++) {
                    var n = integerNum.substr(i, 1);
                    var p = IntLen - i - 1;
                    var q = p / 4;
                    var m = p % 4;
                    if (n == '0') {
                        zeroCount++;
                    } else {
                        if (zeroCount > 0) {
                            chineseStr += cnNums[0];
                        }
                        //归零
                        zeroCount = 0;
                        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                    }
                    if (m == 0 && zeroCount < 4) {
                        chineseStr += cnIntUnits[q];
                    }
                }
                chineseStr += cnIntLast;
            }
            //小数部分
            if (decimalNum != '') {
                var decLen = decimalNum.length;
                for (var i = 0; i < decLen; i++) {
                    var n = decimalNum.substr(i, 1);
                    if (n != '0') {
                        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                    }
                }
            }
            if (chineseStr == '') {
                chineseStr += cnNums[0] + cnIntLast + cnInteger;
            } else if (decimalNum == '' || /^0*$/.test(decimalNum)) {
                chineseStr += cnInteger;
            }
            return chineseStr;
        },
        bigMoney(num) {
            let number = Number(num)
            if (number && number >= 0) {
                return this.numToCapital(number)
            } else if (number && number < 0) {
                return '负' + this.numToCapital(Number(number) * -1)
            }
        },
    },
    beforeDestroy(){
        backfollow.$off('backfollow', this.backfollowListener);
    },
}
</script>

<style lang="scss">
.contractdetail {
    .filedialog{
        width: 800px !important;
        height: 500px !important;
    }
    .top-banner {
        width: 100%;
        height: 112px;
        border-radius: 4px;
        background: linear-gradient(90deg, #FFF 0%, #F0F4FE 50%, #F0F4FE 100%);
        display: flex;
        justify-content: flex-end;

        .background {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 26px 30px;
            display: flex;
            justify-content: space-between;

            .banner-left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .title {
                    font-size: 22px;
                    font-weight: bold;
                    color: #474952;
                    // max-width: 570px;
                    width: auto;
                    display: flex;
                    align-items: center;

                    .tip {
                        width: auto;
                        height: 24px;
                        line-height: 24px;
                        padding: 0 10px;
                        border-radius: 12px 2px 12px 2px;
                        font-size: 14px;
                        margin-left: 10px;
                    }
                    .status1{
                        color: #8C8EA6;
                        background: #DADEEC;
                    }
                    .status2{
                        color: #2C57FF;
                        background: #D4DEFF;
                    }
                    .status3{
                        color: #08B54D;
                        background: #C9EFDD;
                    }
                    .status4{
                        color: #F74B4B;
                        background: #FFE3E3;
                    }
                    .status5{
                        color: #DE8500;
                        background: #FFEECD;
                    }
                }

                .message {
                    display: flex;

                    .line {
                        font-size: 14px;
                        color: #C4C5CB;
                        margin-right: 20px;

                        span {
                            color: #7D7F88;
                        }
                    }

                    .active {
                        color: #8999B1;
                    }
                }
            }

            .banner-right {
                display: flex;
                align-items: center;

                .btn {
                    width: 128px;
                    height: 32px;
                    box-shadow: 0px 2px 4px 0px rgba(55, 59, 92, 0.1);
                    background: linear-gradient(180deg, #F8F8FB 0%, #ECEFF6 100%);
                    border-radius: 4px 4px 4px 4px;
                    outline: none;
                    border: none;
                    font-size: 14px;
                    color: #6B6D77;

                    span {
                        margin-left: 8px;
                    }
                }

                .btn:hover {
                    background: linear-gradient(180deg, #ECEFF6 0%, #F8F8FB 100%);
                }
                .print {
                    width: 84px;
                    height: 32px;
                    // box-shadow: 0px 2px 4px 0px rgba(55, 59, 92, 0.1);
                    background: linear-gradient(180deg, #355EFF 0%, #1744F2 100%);
                    border-radius: 4px 4px 4px 4px;
                    outline: none;
                    border: none;
                    font-size: 14px;
                    color: white;
                    margin-right: 10px;
                    span {
                        margin-left: 8px;
                    }
                }
                
                .print:hover {
                    background: linear-gradient(180deg, #1744F2 0%, #355EFF 100%);
                }
            }
        }
    }

    .main {
        width: 100%;
        height: 700px;
        // background: white;
        margin-top: 10px;
        display: flex;
        .title {
            font-weight: bold;
            font-size: 14px;
        }

        .descript {
            width: calc(100% - 292px);
            height: 700px;
            background-color: white;
            border-radius: 4px;
            overflow-x: auto;
            overflow-y: auto;
            padding-bottom: 52px !important;
            box-sizing: border-box;
            .header{
                height: 55px;
                border-bottom: 1px solid #F0F1F5;
                display: flex;
                padding: 24px 0 0 30px;
                box-sizing: border-box;
                font-size: 14px;
                .tab{
                    width: auto;
                    line-height: 16px;
                    margin-right: 40px;
                    color:#B0B1B9;
                }
                .tab:hover{
                    color:#2C57FF;
                }
                .acttab{
                    border-bottom: 2px solid #2C57FF;
                    font-weight: bold;
                    color:#474952 !important;
                }
            }
            .descript-main{
                padding: 24px 20px;
                box-sizing: border-box;
                .addimg-box{
                    display: flex;
                    align-items: flex-end;
                    .img-total{
                        margin-left: 10px;
                        font-size: 12px;
                        color: rgb(211, 211, 211);
                    }
                }
                .showimage{
                    width: 120px;
                    height: 80px;
                    .tip{
                        background: rgba(51,54,62,0.5);
                        width: 100%; 
                        height: 100%;
                        justify-content: center;
                        align-items: center;
                        color: rgb(211, 211, 211);
                        display: none;
                    } 
                    img{
                        width: 120px;
                        height: 80px;
                    }
                }
                .showimage:hover{
                    .tip{
                        display: flex;
                    }
                }
                .table{
                    .table-title{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 14px;
                        .title{
                            font-weight: bold;
                            color: #6B6D77;
                            margin-bottom: 14px;
                        }
                        .addpart{
                            .icon{
                                fill: #475B93 !important;
                            }
                        }
                        .addpart:hover{
                            color: #2C57FF !important;
                            .svg-icon{
                                fill: #2C57FF !important;
                            }
                        }
                        .addpart:active{
                            color: #133CDC !important;
                            .svg-icon{
                                fill: #133CDC !important;
                            }
                        }
                    }
                }
            }
            .table-part{
                width: 100%;
                height: auto;
                padding: 0 30px;
                box-sizing: border-box;
                .table-header{
                    font-weight: bold;
                    font-size: 14px;
                    color: #474952;
                    line-height: 16px;
                }
                .table{
                    margin-top: 20px;
                }
                .table-tip{
                    font-weight: bold;
                    font-size: 14px;
                    color: #6B6D77;
                    line-height: 14px;
                    margin-left: 28px;
                    margin-top: 18px;
                }
            }
        }
        .detail-right{
            margin-left: 10px;
            width: 282px;
            .timetree{
                width: 100%;
                max-height: calc(100% - 256px);
                height: auto;
                border-radius: 4px;
                background: white;
                padding: 24px 20px;
                box-sizing: border-box;
                .timetitle{
                    padding: 0 7px;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;
                    .icon{
                        margin-right: 5px;
                    }
                }
                .tree{
                    height: calc(100% - 38px);
                    margin-top: 20px;
                    width: 100%;
                    overflow-y:auto ;
                    padding: 0 4px;
                    box-sizing: border-box;
                    .el-timeline-item__node--normal{
                        width: 14px !important;
                        height: 14px !important;
                        left: -3px;
                    }
                    .el-timeline-item__node{
                        background: #FFF !important;
                        border: 1px solid #E2E5EA !important;
                    }
                    .el-timeline-item__wrapper{
                        top: 0 !important;
                        padding-left: 21px !important;
                    }
                    .el-timeline-item {
                        position: relative;
                        padding-bottom: 1px;
                    }
                    .el-timeline-item__tail {
                        border-left:1px solid #E4E7ED !important;
                    }
                    .tree-info{
                        min-height: 64px;
                        width: 100%;
                        border-radius: 4px;
                        background: #E9EAEE;
                        padding: 12px 14px 14px;
                        box-sizing: border-box;
                        .timeline{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 12px;
                            line-height: 14px;
                            .date{
                                color: #B0B1B9;
                            }
                            .status{
                                color: #B0B1B9;
                            }
                        }
                        .checkuser{
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: bold;
                            color: #6B6D77;
                            margin-top: 6px;
                        }
                        .reason{
                            color: #6B6D77;
                            line-height: 20px;
                            font-size: 12px;
                            margin-top: 6px;
                            .reason-title{
                                color:#B0B1B9;
                            }
                        }
                    }
                    .tree1{
                        background: #F0FAF1 !important;
                        .status{
                            color: #10C257 !important;
                        }
                    }
                    .tree2{
                        background: #FAF5E6 !important;
                        .status{
                            color: #DE8500 !important;
                        }
                    }
                    .tree3{
                        background: #FFF4F4 !important;
                        .status{
                            color: #F74B4B !important;
                        }
                    }
                    .tree4{
                        background: #EAF1FB !important;
                        .status{
                            color: #2C57FF !important;
                        } 
                    }
                    .tree5{
                        background: #EAF7FF !important;
                        .status{
                            color: #0C80EB !important;
                        } 
                    }
                }
            }
            .user{
                margin-top: 10px;
                width: 100%;
                height: 246px;
                border-radius: 4px;
                background: white;
                padding: 24px 20px;
                box-sizing: border-box;
                .table-title{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 14px;
                    .title{
                        font-weight: bold;
                        color: #474952;
                        .icon{
                            width: 14px;
                            height: 14px;
                            margin-right: 10px;
                            .svg-icon{
                                fill: #2C57FF !important;
                            }
                        }
                    }
                    .addpart{
                        .icon{
                            fill: #475B93 !important;
                        }
                    }
                    .addpart:hover{
                        color: #2C57FF !important;
                        .svg-icon{
                            fill: #2C57FF !important;
                        }
                    }
                    .addpart:active{
                        color: #133CDC !important;
                        .svg-icon{
                            fill: #133CDC !important;
                        }
                    }
                }
                .userlist{
                    margin-top: 16px;
                    height: 172px;
                    overflow-y: auto;
                    padding-right: 0 !important ;
                    box-sizing: border-box;
                    .el-empty{
                        padding: 10px 0 !important;
                    }
                    .fileline{
                        display: flex;
                        height: 28px;
                        border-radius: 4px;
                        background: #F4F7FC;
                        width: 100%;
                        align-items: center;
                        margin-bottom: 6px;
                        padding: 0 12px 0 14px;
                        justify-content: space-between;
                        box-sizing: border-box;
                        .icon{
                            margin-right: 7px;
                            width: 12px;
                            height: 14px;
                        }
                        .name{
                            font-size: 12px;
                            color: #474952;
                        }
                        .close{
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background: rgba(63,69,124,0.1);
                            display: none;
                            align-items: center;
                            justify-content: center;
                            .closeicon{
                                width: 8px;
                                height: 8px;
                                fill: #B0B1B9;
                            }
                        }
                        .close:hover{
                            background: rgba(44, 87, 255, 0.2) !important;
                            .closeicon{
                                fill: #2C57FF !important;
                            }
                        }
                    }
                    .fileline:hover{
                        background:#EDF3FE !important;
                        .close{
                            display: flex;
                        }
                    }
                    .closepart{
                        display: flex;
                        align-items: center;
                        .close-box{
                            width: 16px;
                        }
                        span{
                            font-size: 14px;
                            line-height: 16px;
                            color: #B0B1B9;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
    .examinebtn.common-examinebtn{
    bottom: -20px !important;
    position: relative !important;
}
    
}</style>
