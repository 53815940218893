<template>
    <div class="custom">
        <div class="border" v-if="!isDetail">
            <div class="top-search">
                <el-form :model="form" class="demo-form-inline" label-width="">
                    <div class="select-line bostrap contract" :style="{ height: (hide == false ? '43px' : 'auto') }" id="searchLine">
                        <el-form-item label="发票单号" class="">
                            <el-input v-model="form.number" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="开票公司" class="">
                            <el-select v-model="form.company"  placeholder="请选择" clearable  filterable>
                                <el-option v-for="item in companyList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="开票形式">
                            <el-select v-model="form.way"  placeholder="请选择" clearable  >
                                <el-option v-for="item in openList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="申请人">
                            <el-input v-model="form.applicant" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="发票类型">
                            <el-select v-model="form.type"  placeholder="请选择" clearable  >
                                <el-option v-for="item in typeLsit" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <!-- <el-form-item label="是否已撤回">
                            <el-select v-model="form.charge_detail"  placeholder="请选择" clearable >
                                <el-option v-for="item in detailList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item> -->
                        <el-form-item label="审核状态">
                            <el-select v-model="form.audit_status"  placeholder="请选择" clearable  >
                                <el-option v-for="item in auditList" :label="item.name" :value="item.id"
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="申请时间" class="time">
                            <el-date-picker v-model="form.open_time" value-format="yyyy-MM-dd" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" type="daterange" placeholder="请选择"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="开票人">
                            <el-select v-model="form.approver"  placeholder="请选择" clearable  multiple collapse-tags filterable >
                                <el-option v-for="item in openSelect" :label="item.name" :value="item.id" 
                                    :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="line"></div>
                    <div class="btn-line">
                        <div class="btnpart">
                            <el-button class="confirm" @click="getList('search')" plain v-permission="'开票管理-select'">查询</el-button>
                            <el-button class="cancel" @click="clearSeach" plain v-permission="'开票管理-reset'">重置</el-button>
                        </div>
                        <div class="showpart hover" @click="showHide">
                            {{ actText }}
                            <local-svg iconClass="showdown" class="icon" :class="hide?'acticon':''"></local-svg>
                        </div>
                    </div>
                </el-form>
            </div>
            <div class="tablepart">
                <div class="top">
                    <div class="span">开票管理</div>
                    <div class="btnlist btnpart">
                        <el-button class="iconbtn" @click="deleteMoreUser" plain v-permission="'开票管理-batch_del'"><local-svg iconClass="delete"
                                class="icon"></local-svg>批量删除</el-button>
                        <el-button class="iconbtn" @click="showDialog = true" plain v-permission="'开票管理-list'"><local-svg iconClass="set" 
                                class="icon"></local-svg>设置列表展示列</el-button>
                        <el-button class="iconbtn" @click="exprotFile" plain v-permission="'开票管理-export'"><local-svg iconClass="expor"
                                class="icon"></local-svg>导出</el-button>
                        <el-button class="iconbtn" @click="beginAdd" plain v-permission="'开票管理-create'"><local-svg iconClass="add"
                                class="icon"></local-svg>新建</el-button>
                    </div>
                </div>
                <customTable v-if="headerList.length > 0" ref="dadtable" :list="tableData" :head="showHeader" @transfer="wantAdd"
                    @tableedit="wantEdit" @stopuser="setUserType" @deluser="deleteUser" @detail="seeDetail" @select="selectRow" :loading="load" @improve="imporveBill">
                </customTable>
                <div v-else style="height:560px"></div>
                <div class="pagetab">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <setshowDialog v-if="showDialog" :list="headerList" :show="showDialog" @show="setShow" @cancel="closeShow">
        </setshowDialog>
        <exportDialog v-if="exportDialog" :list="headerList" :show="exportDialog" @export="exportTable"
            @cancel="cancelExport"></exportDialog>
        <changeDialog v-if="changeDialog" :form="detailData" :show="changeDialog" @cancel="cancelChange" @add="addUser"
            :type="addtype" @edit="deitUser" ></changeDialog>
        <improveDialog v-if="improveDialog" :show="improveDialog" :form="detailData" @close="closeImprove" @improve="improveOk">
        </improveDialog>
        <detailPart v-if="isDetail" @back="closeDetail" :info="detailData" @add="addFollow" :follow="followList" :isDetail="isExamine" @print="printIt" @examine="restInfo"></detailPart>
        <addSelect v-if="followDialog" :show="followDialog" :list="headerList" @add="checkUser" @close="cancelFollow"></addSelect>
        <!-- <iframe src='http://192.168.110.198/storage/image/16a8c947f3f5fdc640267a9984515462.jpg' frameborder="0" style="width:100% ; height: 700px"></iframe> -->
        <div class="printpart" v-show="isPrint">
            <section id="printArea" style="margin-top:60px;" v-if="printElem">
                <p class="printArea-title">
                    <img v-if="inputs.way == 1" height="50" src="@/assets/image/opentext.jpg" alt="">
                    <img v-else height="50" src="@/assets/image/houseopentext.jpg" alt="">
                    <!-- <span style='font-size: 50px;font-weight: bolder;color: black;'>开票申请</span> -->
                    <img src="@/assets/image/printLogo.png" class="printArea-title-img" />
                </p>
                <div style="display:flex;justify-content:space-around;padding-bottom:5px; margin-top: 20px;">
                    <p  style="color: #000 !important;">创建时间： {{ inputs.create_time }}</p>
                    <!-- <p>实际支出时间： {{ inputs.pay_time }}</p> -->
                    <p  style="color: #000 !important;">开票审核状态： {{ inputs.audit_status_id }}</p>
                </div>
                <table class="printTable">
                    <tbody>
                        <tr>
                            <td rowspan="3" colspan="1">销售方信息</td>
                            <td colspan="2">开票公司</td>
                            <td colspan="3">{{ inputs.company_id }}</td>
                            <td colspan="1">是否已回款</td>
                            <td colspan="3">{{ inputs.payback_id }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">开票申请人</td>
                            <td colspan="3">{{ inputs.applicant }}</td>
                            <td rowspan="2" colspan="1">回款单号</td>
                            <td rowspan="2" colspan="3">{{  inputs.payback_id == '是' ? inputs.back_number : '否' }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">发票类型</td>
                            <td colspan="3">{{ inputs.type_id }}</td>
                        </tr>
                        <tr>
                            <td rowspan="4" colspan="1">购买方信息</td>
                            <td colspan="2">名称</td>
                            <td colspan="7">{{ inputs.purchaser_name  }}</td>
                            <!-- <td colspan="2">纳税人识别号</td>
                            <td colspan="3">{{ inputs.identifier }}</td> -->
                        </tr>
                        <tr>
                            <td colspan="2">纳税人识别号</td>
                            <td colspan="7">{{ inputs.identifier  }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">地址、电话</td>
                            <td colspan="7">{{ inputs.purchaser_address }}{{inputs.purchaser_phone}} </td>
                            <!-- <td colspan="2">纳税人识别号</td>
                            <td colspan="3">{{ inputs.identifier }}</td> -->
                        </tr>
                        <tr>
                            <td colspan="2">开户行及账号</td>
                            <td colspan="7">{{ inputs.bank_id }}{{ inputs.account }}</td>
                        </tr>
                        <tr v-if="inputs.way=='1'">
                            <td colspan="2">项目名称</td>
                            <td colspan="1">规格型号</td>
                            <td colspan="1">单位</td>
                            <td colspan="1">数量</td>
                            <td colspan="1">单价</td>
                            <td colspan="1">不含税金额</td>
                            <td colspan="1">税率</td>
                            <td colspan="2">含税金额</td>
                        </tr>
                        <tr v-else>
                            <td colspan="2">项目名称</td>
                            <td colspan="1">面积单位</td>
                            <td colspan="1">面积</td>
                            <td colspan="1">单价</td>
                            <td colspan="2">不含税金额</td>
                            <td colspan="1">税率</td>
                            <td colspan="2">含税金额</td>
                            <!-- <td colspan="1">产权证书/不动产权证号</td>
                            <td colspan="1">面积单位</td>
                            <td colspan="1">数量（房屋租赁面积）</td>
                            <td colspan="1">单价</td>
                            <td colspan="1">不含税金额</td>
                            <td colspan="1">税率</td>
                            <td colspan="2">含税金额（元）</td> -->
                        </tr>
                        <tr v-if="inputs.way=='1'">
                            <td colspan="2">{{ inputs.project }}</td>
                            <td colspan="1">{{ inputs.models }}</td>
                            <td colspan="1">{{ inputs.unit }}</td>
                            <td colspan="1">{{ inputs.quantity }}</td>
                            <td colspan="1">{{ inputs.unit_price }}</td>
                            <td colspan="1">{{ inputs.no_tax_amount }}</td>
                            <td colspan="1">{{ inputs.rate_id }}</td>
                            <td colspan="2">{{ inputs.amount_tax }}</td>
                        </tr>
                        <tr v-else>
                            <td colspan="2">{{ inputs.project }}</td>
                            <td colspan="1">㎡</td>
                            <td colspan="1">{{ inputs.area }}</td>
                            <td colspan="1">{{ inputs.unit_price }}</td>
                            <td colspan="2">{{ inputs.no_tax_amount }}</td>
                            <td colspan="1">{{ inputs.rate_id }}</td>
                            <td colspan="2">{{ inputs.amount_tax }}</td>
                        </tr>
                        <tr v-if="inputs.way=='1'">
                            <td colspan="3">发票备注</td>
                            <td colspan="7">{{ inputs.remark }}</td>
                        </tr>
                        <tr v-else>
                            <td rowspan="3" colspan="2">发票备注</td>
                            <!-- <td rowspan="2" colspan="2">{{ inputs.remark }}</td> -->
                            <td colspan="2">租赁地址</td>
                            <td colspan="6">{{ inputs.address }}</td>
                        </tr>
                        <tr v-if="inputs.way=='2'">
                            <td colspan="2">租赁起止时间</td>
                            <td colspan="6">{{ inputs.start_time }}{{ '-'+inputs.end_time }}</td>
                        </tr>
                        <tr v-if="inputs.way=='2'">
                            <td colspan="2">其他备注</td>
                            <td colspan="6">{{ inputs.remark }}</td>
                        </tr>
                    </tbody>
                </table>
                <div style="display:flex;justify-content:space-around;padding-top:10px;">
                    <p  style="color: #000 !important;"></p>
                    <p  style="color: #000 !important;">开票人：{{ inputs.approver }}</p>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import {openlist,headerlist,addselect,addopne,opendetail,delopen,delmoreopen,editopen } from '@/api/bill'
import {saveheader} from '@/api/common'
import {alluser,allbranch,receivelist,getbusselect} from '@/api/const'
import customTable from "@/components/custom/table.vue"
import setshowDialog from "@/components/custom/show.vue"
import exportDialog from "@/components/custom/export.vue"
import changeDialog from "@/components/openbill/change.vue"
import improveDialog from "@/components/openbill/improve.vue"
import detailPart from "@/components/openbill/detail"
import addSelect from "@/components/contract/follow.vue"
import returnCost from "@/components/constapply/return.vue"
import printJS from "print-js";
export default{
    name: 'custom',
    data() {
        return {
            improveDialog:false,
            receiveDialog:false,
            fileDialog:false,
            followDialog:false,
            showDialog: false,  //显示列表弹窗
            hide: false,
            exportDialog: false,
            changeDialog: false,
            isDetail: false,
            isExamine:false,
            returnDialog:false,
            load: false,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            actText: '展开',
            userList: [],
            openList:[],   //开票类型
            companyList:[],  //申请人列表
            typeLsit:[],   //发票类型
            auditList:[],  //审核状态
            partList: [],   
            exportData:{},
            form: {
                number:'',
                type:'',
                company:'',
                applicant:'',
                way:'',
                audit_status:'',
                approver:[],
                open_time:[]
            },
            improveForm:{},
            tableData: [],
            showHeader: [],
            headerList: [],
            selectionList: [],
            tradeList: [],
            openSelect:[],
            addtype: 1,
            detailData:{},
            followList:[],
            load:true,
            printElem:false,
            inputs:{},
            isPrint:false
        }
    },
    components: {
        customTable,
        setshowDialog,
        exportDialog,
        changeDialog,
        detailPart,
        addSelect,
        returnCost,
        improveDialog
    },
    mounted() {
        if(this.$route.query.id){
            if(this.$route.query.type && this.$route.query.type=='examine'){
                let data = {
                    id:this.$route.query.id
                }
                this.seeDetail(data)
                this.isExamine = true
            }
            if(this.$route.query.type && this.$route.query.type=='improvebill'){
                let data = {
                    id:this.$route.query.id
                }
                this.getImproveDetail(data)
            }
            if(this.$route.query.type && this.$route.query.type=='detail'){
                let data = {
                    id:this.$route.query.id
                }
                this.seeDetail(data)
            }
            if(this.$route.query.type && this.$route.query.type=='generate'){
                this.detailData.way = this.$route.query.way
                this.detailData.client = this.$route.query.client
                this.detailData.client_name = this.$route.query.client_name
                this.detailData.back_id = this.$route.query.id
                this.detailData.house_id = this.$route.query.house_id
                this.detailData.amount = this.$route.query.amount
                this.detailData.source = this.$route.query.source
                this.detailData.channel = this.$route.query.channel ? this.$route.query.channel : ''
                this.detailData.address = this.$route.query.address
                this.detailData.date = this.$route.query.date
                this.beginAdd()
            }
        }
        if(this.$route.params.id){
            if(this.$route.query.type && this.$route.params.type=='examine'){
                let data = {
                    id:this.$route.params.id
                }
                this.seeDetail(data)
                this.isExamine = true
            }
            if(this.$route.params.type && this.$route.params.type=='improvebill'){
                let data = {
                    id:this.$route.params.id
                }
                this.getImproveDetail(data)
            }
            if(this.$route.params.type && this.$route.params.type=='detail'){
                let data = {
                    id:this.$route.params.id
                }
                this.seeDetail(data)
            }
            if(this.$route.params.type && this.$route.params.type=='generate'){
                this.detailData.way = this.$route.params.way
                this.detailData.client = this.$route.params.client
                this.detailData.client_name = this.$route.params.client_name
                this.detailData.back_id = this.$route.params.id
                this.detailData.house_id = this.$route.params.house_id
                this.detailData.amount = this.$route.params.amount
                this.detailData.address = this.$route.params.address
                this.detailData.date = this.$route.params.date
                this.beginAdd()
            }
        }
        this.getSelect()
        this.getBranchList()
        this.getReceiveList()
        this.getOutType()
        this.getHeaderList()
        this.getList()
        this.beginAdd = this.$debounce(this.beginAdd )
        this.exprotFile = this.$debounce(this.exprotFile )
    },
    methods: {
        improveOk(msg){
            this.getList()
            this.improveDialog = false
        },
        getImproveDetail(data){
            let obj = {
                id: data.id
            }
            opendetail(obj).then(res => {
                this.detailData = res.data
                this.improveDialog = true
            })
        },
        imporveBill(msg){
            this.getImproveDetail(msg)
        },
        closeImprove(){
            this.improveDialog = false
        },
        restInfo(msg){
            let data = {
                id:msg
            }
            this.seeDetail(data)
        },
        printIt(msg){
            this.isPrint = true
            this.printElem = true;
            this.inputs = msg
            var that = this;
            setTimeout(() => {
                printJS({
                    style: ['@page { margin: 0cm 1.7cm 0cm 1.8cm;  margin-top: 1mm; margin-bottom: 1mm;}  body{margin-top: 60px;}'],
                    // style: [' @media print{@page {size:landscape}} @page { margin: 10px; } html { padding: 0 40px 0 40px; }  body{margin-top: 40px;}'], // @page { margin: 0px; } body {margin-top: 50px;}
                    printable: "printArea",
                    type: "html",
                    targetStyles: ['*'],
                    onLoadingEnd: function () {
                        that.printElem = false;
                        this.isPrint = false
                    },
                    ignoreElements: ["ignore"],
                });
            }, 200);
        },
        getdetailList(){
            let data = {
                id:this.form.business_type
            }
            getbusselect(data).then(res=>{
                this.detailList = res.data
            })
        },
        getOutType(){
            getbusselect().then(res=>{
                this.singleList = res.data
            })
        },
        getReceiveList(){
            receivelist().then(res=>{
                this.receiveList = res.data
            })
        },
        getBranchList(){
            allbranch().then(res=>{
                this.branchList = res.data
            })
        },
        cancelFollow(){
            this.followDialog = false
        },
        checkUser(msg){
            this.followDialog = false
        },
        addFollow(){
            this.followDialog = true
        },
        getSelect() {
            addselect().then(res => {
                this.openList = res.data.way
                this.companyList = res.data.company
                this.typeLsit = res.data.type
                this.auditList = res.data.audit
                this.openSelect = res.data.admin
            })
        },
        selectRow(row) {
            this.selectionList = row
            console.log(this.selectionList, 111)
        },
        deleteMoreUser() {
            if (this.selectionList.length == 0) {
                this.$message({
                    message: '请先选择要删除的数据！',
                    type: 'error'
                })
                return
            }

            this.$confirm('此操作将永久删除所选数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let array = []
                this.selectionList.forEach(item => {
                    array.push(item.id)
                })
                let data = {
                    ids: array
                }
                delopen(data).then(res => {
                    if (res.code == 200) {
                        this.getList()
                        this.selectionList = []
                        this.changeDialog = false
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        deitUser(msg) {
            let data = {
                id:msg.id,
                audit_status:msg.audit_status,
                way:msg.way,
                client:msg.client,
                company:msg.company,
                applicant:msg.applicant,
                type:msg.type,
                identifier:msg.identifier,
                payback:msg.payback,
                purchaser:msg.purchaser,
                back_number:msg.back_number,
                purchaser_address:msg.purchaser_address,
                purchaser_phone:msg.purchaser_phone,
                account:msg.account,
                project:msg.project,
                rate:msg.rate,
                amount_tax:msg.amount_tax.replace(/,/g, ""),
                remark:msg.remark,
                branch_id:msg.branch_id,
                house_num:msg.house_num,
                area:msg.area,
                rent:msg.rent,
                start_time:msg.set_time[0],  
                end_time:msg.set_time[1],  
                address:msg.address,
                unit_price:msg.unit_price,
                no_tax_amount:msg.no_tax_amount,
                models:msg.models,
                quantity:msg.quantity,
                unit:msg.unit,
            }
            data.back_number = data.back_number.toString()
            this.$loading({ text: '加载中...' });
            editopen(data).then(res => {
                if (res.code == 200) {
                    this.getList()
                    this.changeDialog = false
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        exprotFile() {
            this.exportDialog = true
        },
        seeDetail(msg) {
            let data = {
                id: msg.id
            }
            opendetail(data).then(res => {
                this.detailData = res.data
                this.isDetail = true
            })
        },
        beginAdd() {
            this.addtype = 1
            this.changeDialog = true
        },
        addUser(data) {
            let obj = JSON.parse(JSON.stringify(data))
            obj.amount_tax = obj.amount_tax.replace(/,/g, "")
            obj.back_number = obj.back_number.toString()
            delete obj.purchaser_name
            delete obj.channel
            delete obj.source
            this.$loading({ text: '加载中...' });
            addopne(obj).then(res => {
                if (res.code == 200) {
                    this.getList()
                    this.changeDialog = false
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        getHeaderList() {
            let data = {
                id:8
            }
            headerlist(data).then(res => {
                this.showHeader = []
                this.headerList = res.data
                this.headerList.forEach(item => {
                    this.$set(item, 'check', item.is_show==1?true:false)
                    if(item.is_show==1){
                        this.showHeader.push(item)
                    }
                })
                this.$nextTick(() => {
                    this.$refs.dadtable.$refs.showTable.doLayout()
                })
            })
        },
        closeDetail() {
            this.getList()
            this.isDetail = false
        },
        cancelChange() {
            this.changeDialog = false
            this.detailData = {}
        },
        getSameKey(key,obj2){
            let equalKeys = ''
            // for (const key1 in obj1) {
                if (obj2.hasOwnProperty(key)) {
                    equalKeys = obj2[key]
                }
                console.log(key,111)
                console.log(obj2,2222)
            // }
            return equalKeys
        },
        exportTable(msg) {
            let idlist = []
            msg.forEach(item =>{
                idlist.push(item.id)
            })
            let data = {
                number:this.form.number,
                type:this.form.type,
                company:this.form.company,
                applicant:this.form.applicant,
                way:this.form.way,
                audit_status:this.form.audit_status,
                approver:this.form.approver.toString(),
                start_create_time:this.form.open_time.length>0?this.form.open_time[0]+' 00:00:00':'',
                end_create_time:this.form.open_time.length>0?this.form.open_time[1]+' 23:59:59':'',
            }
            window.open(`${this.$BASE_URL}invoice/export?invoice=${idlist.toString()}&number=${data.number}&type=${data.type}
            &company=${data.company}&applicant=${data.applicant}&way=${data.way}&audit_status=${data.audit_status}&approver=${data.approver}&start_create_time=${data.start_create_time}
            &end_create_time=${data.end_create_time}&token=${localStorage.getItem('token')}`,'_blank')
            this.exportDialog = false
        },
        cancelExport() {
            this.exportDialog = false
        },
        // 设置展示列保存&取消
        setShow(msg) {
            let ids = []
            msg.forEach(item =>{
                ids.unshift(item.id)
            })
            let data = {
                id:ids.toString(),
                type:8
            }
            saveheader(data).then(res =>{
                if(res.code == 200){
                    this.$message.success(res.msg)
                    this.showHeader = msg
                    this.getHeaderList()
                    this.showDialog = false
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        closeShow(msg) {
            this.showDialog = false
        },
        bindUser() { },
        clearSeach() {
            this.form = {
                number:'',
                type:'',
                company:'',
                applicant:'',
                way:'',
                audit_status:'',
                approver:[],
                open_time:[]
            }
            this.getList('search')
        },
        deleteUser(msg) {
            let data = {
                ids: [msg]
            }
            delopen(data).then(res => {
                if (res.code == 200) {
                    this.getList()
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        wantAdd() { },
        // 获取用户列表
        getList(e) {
            if(e){
                this.currentPage = 1
            }
            this.load = true
            let data = {
                page: this.currentPage,
                count: this.pageSize,
                number:this.form.number,
                type:this.form.type,
                company:this.form.company,
                applicant:this.form.applicant,
                way:this.form.way,
                audit_status:this.form.audit_status,
                approver:this.form.approver.toString(),
                start_create_time:this.form.open_time.length>0?this.form.open_time[0]+' 00:00:00':'',
                end_create_time:this.form.open_time.length>0?this.form.open_time[1]+' 23:59:59':'',
            }
            openlist(data).then(res => {
                this.tableData = res.data.data
                this.tableData.forEach(item =>{
                    this.$set(item,'channel',item.channel && item.channel.length>0 ? item.channel.join('、') : '无')
                    this.$set(item,'source',item.source && item.source.length>0 ? item.source.join('、') : '无')
                    let back_arr = []
                    let back_ids = []
                    if(item.back_number_arr && item.back_number_arr.length > 0){
                        item.back_number_arr.forEach(val =>{
                            back_arr.push(val.num)
                            back_ids.push(val.back_id)
                        })
                        this.$set(item,'back_arr',back_arr)
                        this.$set(item,'back_ids',back_ids)
                    }else{
                        this.$set(item,'back_arr',[])
                        this.$set(item,'back_ids',[])
                    }
                })
                this.total = res.data.total
                setTimeout(() => {
                    this.load = false
                }, 1000);
            })
        },
        wantEdit(msg) {
            let data = {
                id: msg.info.id
            }
            opendetail(data).then(res => {
                this.addtype = 2
                this.detailData = res.data
                this.detailData.branch_name = res.data.branch
                this.changeDialog = true
            })
        },
        setUserType() { },
        showHide() {
            this.actText = this.hide ? '展开' : '收起'
            this.hide = !this.hide
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.currentPage = 1
            this.getList()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getList()
        },
    }
}

</script>

<style src="@/assets/scss/formstyle.scss" lang="scss" ></style>
<style lang="scss" scoped>
* {
    box-sizing: content-box;
}

.custom {
    .border{
        padding: 24px;
    }
    .top-search {
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;

        .select-line {
            display: flex;
            padding: 0 20px 0 0;
        }

        .line {
            width: 100%;
            height: 1px;
            background: #F0F1F5;
        }

        .btn-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 11px 20px;

            .showpart {
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #475b93;
                .icon {
                    margin-left: 6px;
                    width: 7px;
                    height: 16px;
                }
            }
            .showpart:hover{
                color: #2C57FF;
            }
            .acticon{
                transform: rotate(180deg);
            }
            .act {
                background: #2C57FF;
                color: white;
            }

            .btnpart button {
                height: 32px;
                width: 68px;
                line-height: 32px;
                padding: 0;

            }
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
            font-weight: bold;
            color: #474952;
            line-height: 16px;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .icon {
            width: 16px;
            height: 16px;
        }

        i {
            margin-right: 5px;
        }
    }

    .tablepart {
        // min-height: 658px;
        background: white;
        margin-top: 10px;
        border-radius: 4px;
        padding: 16px 20px;

        .icon {
            margin-right: 8px;
        }

        .pagetab {
            text-align: right;
            margin-top: 20px;
        }

        .span {
            font-size: 14px;
            font-weight: bold;
            color: #474952;
        }

        .top {
            display: flex;
            align-items: center;
        }
    }
    .iframe-body-sty{position: relative;overflow: hidden;height:600px;width: 1200px;background-color: red;}
	.iframe-body-sty>#iframe-shrink{position: absolute;transform:scale(0.55);left: -400px;top: -170px;}
}
.printpart{
    #printArea {
        height: 200px;
        background-color: #fff;
        margin-top: 200px;
    }

    .printArea-title {
        font-size: 40px;
        font-weight: bold;
        position: relative;
        line-height: 60px;
        height: 60px;
        margin: 0;
        border: 0;
        text-align: center;
    /* margin-top: -500px; */
    }

    .printArea-title-img {
        height: 45px;
        float: left;
        margin: 0;
        border: 0;
        position: absolute;
        left: 0;
    }

    .printTable {
        width: 900px;
        border-collapse: collapse;
        text-align: center;
        table-layout: fixed;
        margin: 0;
        border: 0;
    }

    .printTable tbody tr td {
        border: 1px solid black;
        font-size: 24px;
        height: 50px;
        word-break: break-all;
        word-wrap: break-word;
        margin-top: -50px;
        color: #000 !important;
    }
}

</style>