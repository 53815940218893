<template>
    <div class="custom">
        <div class="top-search" v-if="!isDetail">
            <el-form :model="form" class="demo-form-inline" label-width="">
                <div class="select-line bostrap" :style="{ height: (hide == false ? '43px' : 'auto') }" id="searchLine">
                    <el-form-item label="" class="" style="margin-left: 20px;">
                        <el-input class="input-height select-input" v-model="form.keyword" placeholder="请输入关键字搜索">
                            <el-select slot="prefix" placeholder="请选择" v-model="form.keytype">
                                <el-option v-for="item in selectList" :label="item.name" :value="item.id"
                                    :key="item.value"></el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="负责人">
                        <el-select v-model="form.user" placeholder="请选择" clearable filterable>
                            <el-option v-for="item in userList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属部门">
                        <el-select v-model="form.branch" placeholder="请选择" clearable filterable>
                            <el-option v-for="item in partList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="企业类型">
                        <el-select v-model="form.type" placeholder="请选择" clearable>
                            <el-option v-for="item in typeList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="行业">
                        <el-select v-model="form.trade_id" placeholder="请选择" clearable filterable>
                            <el-option v-for="item in tradeList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="创建时间" class="time">
                        <el-date-picker v-model="form.set_time" value-format="yyyy-MM-dd" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" type="daterange"
                            placeholder="请选择"></el-date-picker>
                    </el-form-item>
                </div>
                <div class="line"></div>
                <div class="btn-line">
                    <div class="btnpart">
                        <el-button class="confirm" @click="getList('search')" plain v-permission="'客户管理-select'">查询</el-button>
                        <el-button class="cancel" @click="clearSeach" plain v-permission="'客户管理-reset'">重置</el-button>
                    </div>
                    <div class="showpart hover handel" @click="showHide">
                        {{ actText }}
                        <div class="img1">
                            <local-svg iconClass="showdown" class="icon" :class="hide?'acticon':''"></local-svg>
                        </div>
                        
                    </div>
                </div>

            </el-form>
        </div>
        <div class="tablepart main-table" v-if="!isDetail">
            <div class="top">
                <div class="span">全部客户</div>
                <div class="btnlist button-part">
                    <button class="cancel-icon hover" @click="deleteMoreUser" v-permission="'客户管理-batch_del'"><local-svg iconClass="delete"
                    class="icon"></local-svg>批量删除</button>
                    <button class="cancel-icon hover" @click="console.log(1)" v-permission="'客户管理-sync'"><local-svg iconClass="refsh"
                    class="icon"></local-svg>数据同步</button>
                    <button class="cancel-icon hover" @click="showDialog = true" v-permission="'客户管理-list'"><local-svg iconClass="set"
                    class="icon"></local-svg>设置列表展示列</button>
                    <button class="cancel-icon hover" @click="exprotFile" v-permission="'客户管理-export'"><local-svg iconClass="expor"
                    class="icon"></local-svg>导出</button>
                    <button class="cancel-icon hover" @click="enterpriseDialog = true,enterpirse_type = 2" v-permission="'客户管理-addPersonClient'"><local-svg iconClass="add"
                    class="icon"></local-svg>新建个体客户</button>
                    <button class="confirm-icon hover" @click="enterpriseDialog = true,enterpirse_type = 1" v-permission="'客户管理-addCompanyClient'"><local-svg iconClass="add"
                    class="icon"></local-svg>新建企业客户</button>
                </div>
            </div>
            <customTable v-if="headerList.length > 0" ref="dadtable" :list="tableData" :head="showHeader" @transfer="wantAdd"
                @tableedit="wantEdit" @stopuser="setUserType" @deluser="deleteUser" @detail="seeDetail" @select="selectRow" :loading="load">
            </customTable>
            <div v-else style="height:560px"></div>
            <div class="pagetab">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <setshowDialog v-if="showDialog" :list="headerList" :show="showDialog" @show="setShow" @cancel="closeShow">
        </setshowDialog>
        <exportDialog v-if="exportDialog" :list="headerList" :show="exportDialog" @export="exportTable"
            @cancel="cancelExport"></exportDialog>
        <changeDialog v-if="changeDialog" :form="detailData" :show="changeDialog" @cancel="cancelChange" ></changeDialog>
        <detailPart v-if="isDetail" @back="closeDetail" :form="detailData" :readDetail="readDetail" :from="detail_form" :is_examine="is_examine" @examine="examineOk" :out_number="out_number" :remind_id="remind_id" ref="detail"></detailPart>
        <enterpriseChange v-if="enterpriseDialog" :show="enterpriseDialog" @close="closeEnterprise" :type="enterpirse_type"></enterpriseChange>
    </div>
</template>

<script>
import { customlist, headlist, saerchlist, addcustom, customdetail, exportfile, editcustom, deluser, delmoreuser, selectlist,com_customlist } from '@/api/custom'
import {saveheader} from '@/api/common'
import customTable from "@/components/custom/table.vue"
import setshowDialog from "@/components/custom/show.vue"
import exportDialog from "@/components/custom/export.vue"
import changeDialog from "@/components/custom/change-clone.vue"
import detailPart from "@/components/custom/detail-clone.vue"
import enterpriseChange from "@/components/custom/enterprise.vue"
export default {
    name: 'custom',
    data() {
        return {
            showDialog: false,  //显示列表弹窗
            hide: false,
            exportDialog: false,
            changeDialog: false,
            isDetail: false,
            enterpriseDialog:false,
            is_examine:false,
            load: false,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            enterpirse_type:1,
            actText: '展开',
            detail_form:'OA',
            remind_id:'',
            out_number:'',
            userList: [],
            typeList: [],
            selectList: [
                { name: '客户名称', id: 2 },
                { name: '法定代表人', id: 1 },
            ],
            partList: [],
            form: {
                branch: '',
                type: '',
                user: '',
                set_time: [],
                keyword: '',
                keytype: 2,
                trade_id:'',
                type_id: []
            },
            tableData: [],
            showHeader: [
            ],
            headerList: [
            ],
            selectionList: [],
            tradeList: [],
            addtype: 1,
            detailData: {},
            readDetail:0,
            load:true
        }
    },
    components: {
        customTable,
        setshowDialog,
        exportDialog,
        changeDialog,
        detailPart,
        enterpriseChange
    },
    mounted() {
        this.getSelect()
        this.getSearch()
        this.getList()
        this.getHeaderList()
        if (this.$route.query.id) {
            let data = {
                id: this.$route.query.id
            }
            if(this.$route.query.type && this.$route.query.type == 'confirm'){
                this.is_examine = true
                this.out_number = this.$route.query.out_name
                this.remind_id = this.$route.query.remind_id
            }
            this.seeDetail(data)
            this.readDetail = 1
        }
        if (this.$route.params.id) {
            let data = {
                id: this.$route.params.id
            }
            if(this.$route.params.type && this.$route.params.type == 'confirm'){
                this.is_examine = true
                this.out_number = this.$route.params.out_name
                this.remind_id = this.$route.params.remind_id
            }
            this.seeDetail(data)
            this.readDetail = 1
        }
        this.beginAdd = this.$debounce(this.beginAdd )
        this.exprotFile = this.$debounce(this.exprotFile )
        this.deleteMoreUser = this.$debounce(this.deleteMoreUser )
    },
    methods: {
        getSelect() {
            selectlist().then(res => {
                this.tradeList = res.data.option.trade
            })
        },
        selectRow(row) {
            this.selectionList = row
        },
        examineOk(){
            this.is_examine = false
            this.seeDetail({id:this.detailData.id},'change')
            
        },
        deleteMoreUser() {
            if (this.selectionList.length == 0) {
                this.$message({
                    message: '请先选择要删除的客户！',
                    type: 'error'
                })
                return
            }

            this.$confirm('此操作将永久删除所选用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let array = []
                this.selectionList.forEach(item => {
                    array.push(item.id)
                })
                let data = {
                    b_id: array.toString()
                }
                delmoreuser(data).then(res => {
                    if (res.code == 200) {
                        this.getList()
                        this.selectionList = []
                        this.changeDialog = false
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        deitUser(msg) {
            this.$loading({ text: '加载中...' });
            let data = {
                id: msg.id,
                name: msg.name,
                legal: msg.legal,
                trade_id: msg.trade_id,
                connect: msg.connect,
                address: msg.address,
                domicile: msg.domicile,
                source_id: msg.source_id,
                pioneer: msg.pioneer,
                category_id: msg.category_id,
                remark: msg.remark,
                type_id: msg.type_id,
                business: msg.business,
                set_time: msg.set_time,
                branch_id: msg.branch_id,
            }
            editcustom(data).then(res => {
                if (res.code == 200) {
                    this.getList()
                    this.changeDialog = false
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        exprotFile() {
            this.exportDialog = true
        },
        seeDetail(msg,type) {
            // let data = {
            //     name:msg.name
            // }
            // com_customlist(data).then(res => {
                // this.detailData = res.data[0]
                // this.detail_form = msg.system
                // this.isDetail = true
            //     // this.detailData = res.data
            //     // this.detailData.connect = res.data.linkman.toString()
            //     // let branch = []
            //     // if(this.detailData.admin_branch.length>0){
            //     //     this.detailData.admin_branch.forEach(item =>{
            //     //         branch.push(item.name)
            //     //     })
            //     // }
            //     // this.detailData.branch = branch.toString()
            //     // this.isDetail = true
            // })
            let data = {
                client_id: msg.id
            }
            customdetail(data).then(res => {
                this.detailData = res.data
                this.detail_form = msg.system ? msg.system :'OA'
                this.isDetail = true
                if(type == 'change'){
                    this.$refs.detail.getInfo(this.detailData )
                }
                // this.detailData = res.data
                // this.detailData.connect = res.data.linkman.toString()
                // let branch = []
                // if(this.detailData.admin_branch.length>0){
                //     this.detailData.admin_branch.forEach(item =>{
                //         branch.push(item.name)
                //     })
                // }
                // this.detailData.branch = branch.toString()
                // this.isDetail = true
            })
        },
        beginAdd(){
            this.$nextTick(()=>{
                this.addtype = 1
                this.changeDialog = true
            })
        },
        addUser(data) {
            this.$loading({ text: '加载中...' });
            addcustom(data).then(res => {
                if (res.code == 200) {
                    this.getList()
                    this.changeDialog = false
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        getSearch() {
            saerchlist().then(res => {
                this.typeList = res.data.type
                this.userList = res.data.admin
                this.partList = res.data.branch
            })
        },
        getHeaderList() {
            let data = {
                id:1
            }
            headlist(data).then(res => {
                this.showHeader = []
                this.headerList = res.data
                this.headerList.forEach(item => {
                    this.$set(item, 'check', item.is_show==1?true:false)
                    if(item.is_show==1){
                        this.showHeader.push(item)
                    }
                })
                this.$nextTick(() => {
                    this.$refs.dadtable.$refs.showTable.doLayout()
                })
            })
        },
        closeDetail() {
            this.isDetail = false
        },
        cancelChange() {
            this.changeDialog = false
            this.getList()
        },
        closeEnterprise(){
            this.enterpriseDialog = false
            this.getList()
        },
        exportTable(msg) {
            let list = []
            msg.forEach(item => {
                list.push(item.id)
            })
            let data = {
                admin_id: this.form.user,
                branch_id: this.form.branch,
                type_id: this.form.type,
                c_id: this.form.keytype,
                search: this.form.keyword,
                start: this.form.set_time.length>0?this.form.set_time[0]:'',
                end: this.form.set_time.length>0?this.form.set_time[1]:'',
                trade_id: this.form.trade_id,
                client: msg
            }
            window.open(`${this.$BASE_URL}client/export?client=${list.toString()}&admin_id=${data.admin_id}&branch_id=${data.branch_id}&type_id=${data.type_id}
            &c_id=${data.c_id}&search=${data.search}&start=${data.start}&end=${data.end}&trade_id=${data.trade_id}&token=${localStorage.getItem('token')}`, '_blank');
            return
            exportfile(data).then(res => {
                if (res.code == 200) {
                    window.open(res.url, '_blank');

                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                    this.exportDialog = false
                } else {

                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        cancelExport() {
            this.exportDialog = false
        },
        // 设置展示列保存&取消
        setShow(msg) {
            let ids = []
            msg.forEach(item =>{
                ids.unshift(item.id)
            })
            let data = {
                id:ids.toString(),
                type:1
            }
            saveheader(data).then(res =>{
                if(res.code == 200){
                    this.$message.success(res.msg)
                    this.getHeaderList()
                    this.showDialog = false
                    this.showHeader = msg
                }else{
                    this.$message.error(res.msg)
                }
            })
            
        },
        closeShow(msg) {
            this.showDialog = false
        },
        bindUser() { },
        clearSeach() {
            this.form = {
                branch: '',
                type: '',
                user: '',
                set_time: [],
                keyword: '',
                keytype: 2,
                type_id: [],
                trade_id:''
            }
            this.getList('search')
        },
        deleteUser(msg) {
            let data = {
                id: msg
            }
            deluser(data).then(res => {
                if (res.code == 200) {
                    this.getList()
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        wantAdd() { },
        // 获取用户列表
        getList(e) {
            if(e){
                this.currentPage = 1
            }
            this.load = true
            let data = {
                page: this.currentPage,
                count: this.pageSize,
                admin_id: this.form.user,
                branch_id: this.form.branch,
                type_id: this.form.type,
                c_id: this.form.keytype,
                search: this.form.keyword,
                start: this.form.set_time.length>0?this.form.set_time[0]:'',
                end: this.form.set_time.length>0?this.form.set_time[1]:'',
                trade_id: this.form.trade_id
            }
            customlist(data).then(res => {
                this.tableData = res.data.table.table
                this.total = res.data.table.total
                setTimeout(() => {
                    this.load = false
                }, 1000);
            })
        },
        wantEdit(msg) {
            console.log(msg,111)
            let data = {
                name:msg.info.name
            }
            com_customlist(data).then(res => {
                this.detailData = res.data[0]
                this.changeDialog = true
            })


            // let data = {
            //     id: msg.info.id
            // }
            // customdetail(data).then(res => {
            //     this.addtype = 2
            //     this.detailData = res.data
            //     this.changeDialog = true
            // })
        },
        setUserType() { },
        showHide() {
            this.actText = this.hide ? '展开' : '收起'
            this.hide = !this.hide
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.currentPage = 1
            this.getList()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getList()
        },
    }
}

</script>

<style src="@/assets/scss/formstyle.scss" lang="scss" ></style>
<style lang="scss" scoped>
* {
    box-sizing: content-box;
}

.custom {
    padding: 24px;

    .top-search {
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;

        .select-line {
            display: flex;
            padding: 0 20px 0 0;
        }

        .line {
            width: 100%;
            height: 1px;
            background: #F0F1F5;
        }

        .btn-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 11px 20px;

            .showpart {
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 14px;

                .icon {
                    margin-left: 6px;
                    width: 7px;
                    height: 18px;
                }
                .acticon{
                    transform: rotate(180deg);
                }
            }
            .showpart:hover{
                .fill{
                    color: #2C57FF;
                }
            }

            .act {
                background: #2C57FF;
                color: white;
            }

            .btnpart button {
                height: 32px;
                width: 68px;
                line-height: 32px;
                padding: 0;

            }
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
            font-weight: bold;
            color: #474952;
            line-height: 16px;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .icon {
            width: 16px;
            height: 16px;
        }

        i {
            margin-right: 5px;
        }
    }

    .tablepart {
        // min-height: 658px;
        background: white;
        margin-top: 10px;
        border-radius: 4px;
        padding: 16px 20px;

        .icon {
            margin-right: 8px;
        }

        .pagetab {
            text-align: right;
            margin-top: 20px;
        }

        .span {
            font-size: 14px;
            font-weight: bold;
            color: #474952;
        }

        .top {
            display: flex;
            align-items: center;
        }
        .button-part{
            button{
                margin-left: 10px;
            }
        }
    }
}
</style>