<template>
    <div class="custom">
        <div class="top-search">
            <el-form :model="form" class="demo-form-inline" label-width="">
                <div class="select-line bostrap" :style="{ height: (hide == false ? '43px' : 'auto') }" id="searchLine">
                    <el-form-item label="用户名称">
                        <el-select v-model="form.admin_id" placeholder="请输入用户名" clearable filterable>
                            <el-option v-for="item in adminList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="line"></div>
                <div class="btn-line">
                    <div class="btnpart">
                        <el-button class="confirm " @click="getList('search')" plain>查询</el-button>
                        <el-button class="cancel" @click="clearSeach" plain>重置</el-button>
                    </div>
                </div>

            </el-form>
        </div>
        <div class="tablepart">
            <div class="top">
                <div class="span">
                    <div class="title">操作日志</div>
                </div>
            </div>
            <customTable :list="tableData" @transfer="wantAdd" @stopuser="setUserType">
            </customTable>
            <div class="pagetab">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { systemselect,systemlist } from '@/api/system'
import customTable from "@/components/system/table.vue"
export default {
    name: 'custom',
    data() {
        return {
            hide: false,
            load: false,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            actText: '展开',
            adminList: [],
            typeList: [],
            form: {
                admin_id:''
            },
            tableData: [],
        }
    },
    components: {
        customTable,
    },
    mounted() {
        this.getSearch()
        this.getList()
    },
    methods: {
        getSearch() {
            systemselect().then(res => {
                this.adminList = res.data
            })
        },
        clearSeach() {
            this.form = {
                admin_id:''
            }
            this.getList('search')
        },
        wantAdd() { },
        // 获取用户列表
        getList(e) {
            if(e){
                this.currentPage = 1
            }
            let data = {
                page: this.currentPage,
                count: this.pageSize,
                admin_id:this.form.admin_id,
            }
            systemlist(data).then(res => {
                this.tableData = res.data.data
                this.total = res.data.total
            })
        },
        setUserType() { },
        showHide() {
            this.actText = this.hide ? '展开' : '收起'
            this.hide = !this.hide
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.currentPage = 1
            this.getList()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getList()
        },
    }
}

</script>

<style src="@/assets/scss/formstyle.scss" lang="scss" ></style>
<style lang="scss" scoped>
* {
    box-sizing: content-box;
}

.custom {
    padding: 24px;

    .top-search {
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;

        .select-line {
            display: flex;
            padding: 0 20px 0 0;
        }

        .line {
            width: 100%;
            height: 1px;
            background: #F0F1F5;
        }

        .btn-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 11px 20px;

            .showpart {
                height: 100%;
                display: flex;
                align-items: baseline;
                font-size: 14px;

                .icon {
                    margin-left: 6px;
                    width: 8px;
                    height: 8px;
                }
            }

            .act {
                background: #2C57FF;
                color: white;
            }

            .btnpart button {
                height: 32px;
                width: 68px;
                line-height: 32px;
                padding: 0;

            }
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
            font-weight: bold;
            color: #474952;
            line-height: 16px;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .icon {
            width: 16px;
            height: 16px;
        }

        i {
            margin-right: 5px;
        }
    }

    .tablepart {
        // min-height: 658px;
        background: white;
        margin-top: 10px;
        border-radius: 4px;
        padding: 16px 20px;

        .icon {
            margin-right: 8px;
        }

        .pagetab {
            text-align: right;
            margin-top: 20px;
        }

        .span {
            font-size: 14px;
            font-weight: bold;
            color: #474952;
            display: flex;
            align-items: center;
            .title{
                margin-right: 30px;
            }
        }

        .top {
            display: flex;
            align-items: center;
        }
    }
}
</style>